import React, { Component } from 'react'
import queryString from 'query-string'

import {
    validatorPhoneNumber,
    validatorLandline,
    validatorPincode,
    validatorPassword,
    validatorCPF,
    validatorDate,
    validatorCEP,
    validatorIdenticalEmail
} from '../../../utils'

import {
    sendPincode,
    validatePincode,
    sendCreateUserWithSale
} from '../../../services'

import ModalTemplate from '../Template'
import FormSendPincode from '../../Forms/Modal/CreateUserPassword/SendPincode'
import FormValidatePincode from '../../Forms/Modal/CreateUserPassword/ValidatePincode'
import FormSendRegistrationData from '../../Forms/Modal/CreateUserPassword/SendRegistrationData'
import FormSendRegistrationLocation from '../../Forms/Modal/CreateUserPassword/SendRegistrationLocation'
import FormSendRegistrationLogin from '../../Forms/Modal/CreateUserPassword/SendRegistrationLogin'
import FormSuccessNewSubscriptionProducts from '../../Forms/Modal/CreateUserPassword/SuccessNewSubscriptionProducts'
import Loading from '../../Loading'

export default class ModalCreateUserPassword extends Component {

    /**
     * Constructor
     */
    constructor() {
        super();

        this.state = {
            loading: false,
            errorMessage: null,
            displayHeader: true,
            displayErrorMessage: false,
            displayModal: false,
            displayFormSendPincode: false,
            displayFormValidatePincode: false,
            displayFormSendRegistrationData: true,
            displayFormSendRegistrationLocation: false,
            displayFormSendRegistrationLogin: false,
            displayFormSuccessNewSubscriptionProducts: false,
            phone: '',
            transaction_id: '',
            pincode: '',
            fs_custom_token: '',
            custom_token: '',
            fullname: '',
            date: '',
            cpf: '',
            cep: '',
            address: '',
            addressNumber: '',
            neighborhood: '',
            city: '',
            state: '',
            addressComplement: '',
            email: '',
            confirmEmail: '',
            password: '',
            partner: 'vivo fixa',
            identifier: 'portalseguros',
            productId: '',
            btnResendPincode: true,
            messageResendPincode: false,
            countResendPincode: 60
        }

        this.baseState = this.state;
        this.onBlurSearchCep = this.onBlurSearchCep.bind(this);
        this.handleResendPincode = this.handleResendPincode.bind(this);
        this.onCloseErrorMessage = this.onCloseErrorMessage.bind(this);
        this.handleChangeFormSendPincode = this.handleChangeFormSendPincode.bind(this);
        this.handleSubmitFormSendPincode = this.handleSubmitFormSendPincode.bind(this);
        this.handleChangeFormValidatePincode = this.handleChangeFormValidatePincode.bind(this);
        this.handleSubmitFormValidatePincode = this.handleSubmitFormValidatePincode.bind(this);
        this.handleChangeFormSendRegistrationData = this.handleChangeFormSendRegistrationData.bind(this);
        this.handleSubmitFormSendRegistrationData = this.handleSubmitFormSendRegistrationData.bind(this);
        this.handleChangeFormSendRegistrationLocation = this.handleChangeFormSendRegistrationLocation.bind(this);
        this.handleSubmitFormSendRegistrationLocation = this.handleSubmitFormSendRegistrationLocation.bind(this);
        this.handleChangeFormSendRegistrationLogin = this.handleChangeFormSendRegistrationLogin.bind(this);
        this.handleSubmitFormSendRegistrationLogin = this.handleSubmitFormSendRegistrationLogin.bind(this);
    }

    /**
     * Component Did Mount
     */
    componentDidMount(){
        const params = queryString.parse(window.location.search);
        if(params.subscription === 'true'){
            this.setState({
                displayModal: true,
                displayFormSendRegistrationData: true,
                productId: params.productId
            });
        }
    }

    /**
     * On Click Modal Display
     */
    onClickModalDisplay = (status, e) => {
        e.preventDefault();
        if (status) {
            this.setState({ displayModal: true });
            return;
        }

        this.setState(this.baseState);
        return;
    }

    /**
     * On Click Back Send Pincode
     */
    onClickBackSendPincode = (e) => {
        e.preventDefault();
        this.setState({
            phone: null,
            transaction_id: null,
            pincode: null,
            displayFormSendPincode: true,
            displayFormValidatePincode: false
        });
    }

    /**
     * On Click Return Form Send Registration Data
     */
    onClickReturnFormSendRegistrationData = (e) => {
        e.preventDefault();
        this.setState({
            displayFormSendRegistrationData: true,
            displayFormSendRegistrationLocation: false
        });
    }

    /**
     * On Click Return Form Send Registration Data
     */
    onClickReturnFormSendRegistrationLocation = (e) => {
        e.preventDefault();
        this.setState({
            displayFormSendRegistrationLocation: true,
            displayFormSendRegistrationLogin: false
        });
    }    

    /**
     * On Close Error Message
     */
    onCloseErrorMessage = () => {
        this.setState({
            displayErrorMessage: false,
            errorMessage: null
        });
    }

    /**
     * On Blur Search Cep
     */
    async onBlurSearchCep(value) {

        this.setState({
            address: '',
            city: '',
            state: '',
            addressComplement: '',
            neighborhood: ''
        });

        if (value !== '') {
            const response = await validatorCEP(value);

            if (!response.erro) {
                this.setState({
                    address: response.logradouro,
                    city: response.localidade,
                    state: response.uf,
                    addressComplement: response.complemento,
                    neighborhood: response.bairro
                });
            }
        }
    }

    /**
     * Handle Change Form Send Pincode
     * @param {event} e 
     */
    handleChangeFormSendPincode(e) {
        this.setState({ phone: e.target.value });
    }

    /**
     * Handle Submit Form Send Pincode
     * @param {event} e 
     */
    async handleSubmitFormSendPincode(e) {
        e.preventDefault();

        const { phone } = this.state;

        const validator = validatorPhoneNumber(phone);

        if (!validator.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: validator.message
            });
            return;
        }

        this.setState({
            loading: true,
            displayErrorMessage: false,
            errorMessage: null
        });

        const response = await sendPincode({
            msisdn: validator.phone
        });

        if (!response.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: response.message
            });
            return;
        }

        this.setState({
            loading: false,
            phone: validator.phone,
            displayFormSendPincode: false,
            displayFormValidatePincode: true,
            transaction_id: response.transaction_id
        });
    }

    /**
     * Handle Change Form Validate Pincode
     * @param {event} e 
     */
    handleChangeFormValidatePincode(e) {
        this.setState({ pincode: e.target.value });
    }

    /**
     * Handle Submit Form Validate Pincode
     * @param {event} e 
     */
    async handleSubmitFormValidatePincode(e) {
        e.preventDefault();

        const {
            transaction_id,
            pincode
        } = this.state;

        const validator = validatorPincode(pincode);

        if (!validator.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: validator.message
            });
            return;
        }

        this.setState({
            loading: true,
            displayErrorMessage: false,
            errorMessage: null
        });

        const response1 = await validatePincode({
            transaction_id: transaction_id,
            pincode: pincode
        });

        if (response1.code) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: response1.message,
                disabledButton: true,
                btnResendPincode: 'none'
            });
            return;
        }

        if (!response1.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: response1.message
            });
            return;
        }

        this.setState({
            loading: false,
            displayFormValidatePincode: false,
            displayFormSendRegistrationData: true
        });
    }

    /**
    * Handle Resend Pincode
    * @param {event} event 
    */
    async handleResendPincode() {
        const { phone, partner } = this.state;

        var intervalId = setInterval(() => {
            let count = this.state.countResendPincode - 1;
            if (count >= 1) {
                this.setState({
                    countResendPincode: count,
                    disabledButton: true
                });
            } else {
                clearInterval(this.state.intervalId);
                this.setState({
                    disabledButton: false,
                    btnResendPincode: true,
                    messageResendPincode: false,
                    countResendPincode: 10
                });
            }
        }, 1000);

        const response = await sendPincode({
            msisdn: phone
        }, partner);

        if (!response.status) {
            this.setState({
                disabledButton: false,
                displayErrorMessage: true,
                errorMessage: response.message
            });
            return;
        }

        this.setState({
            disabledButton: false,
            btnResendPincode: false,
            messageResendPincode: true,
            intervalId: intervalId,
            transaction_id: response.transaction_id
        });
    }

    /**
     * Handle Change Form Send Registration Data
     * @param {event} e 
     */
    handleChangeFormSendRegistrationData(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    /**
     * Handle Submit Form Send Registration Data
     * @param {event} e 
     */
    async handleSubmitFormSendRegistrationData(e) {
        e.preventDefault();

        const {
            fullname,
            phone,
            date,
            cpf
        } = this.state;

        const validator = await validatorLandline(phone);

        if (!validator.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: validator.message
            });
            return;
        }

        const validator2 = validatorDate(date);

        if (!validator2.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: validator2.message
            });
            return;
        }

        const validator3 = validatorCPF(cpf);

        if (!validator3.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: validator3.message
            });
            return;
        }

        this.setState({
            loading: true,
            displayErrorMessage: false,
            errorMessage: null
        });        

        this.setState({
            loading: false,
            fullname: fullname,
            phone: validator.phone,
            date: validator2.date,
            cpf: validator3.cpf,
            displayFormSendRegistrationData: false,
            displayFormSendRegistrationLocation: true
        });
    }

    /**
     * Handle Change Form Send Registration Location
     * @param {event} e 
     */
    handleChangeFormSendRegistrationLocation(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    /**
     * Handle Submit Form Send Registration Location
     * @param {event} e 
     */
    async handleSubmitFormSendRegistrationLocation(e) {
        e.preventDefault();

        const {
            cep,
            address,
            addressNumber,
            city,
            state,
            addressComplement,
        } = this.state;

        this.setState({
            loading: true,
            displayErrorMessage: false,
            errorMessage: null
        });        

        this.setState({
            loading: false,
            cep: cep,
            address: address,
            addressNumber: addressNumber,
            city: city,
            state: state,
            addressComplement: addressComplement,
            displayFormSendRegistrationLocation: false,
            displayFormSendRegistrationLogin: true
        });
    }

    /**
     * Handle Change Form Send Registration Login
     * @param {event} e 
     */
    handleChangeFormSendRegistrationLogin(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    /**
     * Handle Submit Form Send Registration Login
     * @param {event} e 
     */
    async handleSubmitFormSendRegistrationLogin(e) {
        e.preventDefault();

        const {
            email,
            confirmEmail,
            password,
            partner,
            phone,
            fullname,
            productId,
            identifier,
            cep,
            neighborhood,
            address,
            date,
            addressNumber,
            addressComplement,
            state,
            city,
            cpf          
        } = this.state;

        const validator1 = validatorIdenticalEmail(email, confirmEmail);

        if (!validator1.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: validator1.message
            });
            return;
        }

        const validator2 = validatorPassword(password);

        if (!validator2.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: validator2.message
            });
            return;
        }        

        this.setState({
            loading: true,
            displayErrorMessage: false,
            errorMessage: null
        });

        const response = await sendCreateUserWithSale({
            campaign_id: productId,
            identifier: identifier,
            fullname: fullname,
            password: password,
            birth_date: date,
            phone: `55${phone}`,
            cpf: cpf,
            email: email,
            partner: partner,
            address_complement: addressComplement,
            address_number: addressNumber,
            address: {
                cep: cep.replace('-', ''),
                description: address,
                neighborhood: {
                    description: neighborhood,
                    city: {
                        description: city,
                        uf: {
                            description: state,
                            code: state
                        }
                    }
                }
            }
        });

        if (response.status === 'info') {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: response.message
            });
            return;
        }          

        if (!response.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: response.message
            });
            return;
        }        

        this.setState({
            loading: false,
            confirmEmail: confirmEmail,
            password: password,
            displayFormSendRegistrationLogin: false,
            displayFormSuccessNewSubscriptionProducts: true
        });
    }

    /**
     * Render Component
     */
    render() {
        const {
            loading,
            errorMessage,
            displayErrorMessage,
            displayModal,
            displayFormSendPincode,
            displayFormValidatePincode,
            displayFormSendRegistrationData,
            displayFormSendRegistrationLocation,
            displayFormSendRegistrationLogin,
            displayFormSuccessNewSubscriptionProducts,
            phone,
            transaction_id,
            date,
            cpf,
            cep,
            address,
            addressNumber,
            city,
            state,
            neighborhood,
            addressComplement,
            email,
            confirmEmail,
            password,
            countResendPincode,
            btnResendPincode,
            messageResendPincode
        } = this.state;

        return (
            <>
                {/* <button anime="bg-transition" bt="filled" onClick={(e) => this.onClickModalDisplay(true, e)}>Criar senha de acesso</button> */}

                <ModalTemplate
                    display={displayModal}
                    headerTitle={'Criar senha de acesso'}
                    onClickModalDisplay={this.onClickModalDisplay}
                >
                    <Loading display={loading} />

                    <FormSendPincode
                        phone={phone}
                        display={displayFormSendPincode}
                        errorMessage={errorMessage}
                        displayErrorMessage={displayErrorMessage}
                        handleChange={this.handleChangeFormSendPincode}
                        handleSubmit={this.handleSubmitFormSendPincode}
                        onCloseErrorMessage={this.onCloseErrorMessage}
                    />

                    <FormValidatePincode
                        phone={phone}
                        transaction_id={transaction_id}
                        display={displayFormValidatePincode}
                        errorMessage={errorMessage}
                        countResendPincode={countResendPincode}
                        btnResendPincode={btnResendPincode}
                        messageResendPincode={messageResendPincode}
                        displayErrorMessage={displayErrorMessage}
                        onClickBackSendPincode={this.onClickBackSendPincode}
                        handleResendPincode={this.handleResendPincode}
                        handleChange={this.handleChangeFormValidatePincode}
                        handleSubmit={this.handleSubmitFormValidatePincode}
                        onCloseErrorMessage={this.onCloseErrorMessage}
                    />

                    <FormSendRegistrationData
                        phone={phone}
                        date={date}
                        cpf={cpf}
                        errorMessage={errorMessage}
                        display={displayFormSendRegistrationData}
                        displayErrorMessage={displayErrorMessage}
                        handleChange={this.handleChangeFormSendRegistrationData}
                        handleSubmit={this.handleSubmitFormSendRegistrationData}
                        onCloseErrorMessage={this.onCloseErrorMessage}
                    />

                    <FormSendRegistrationLocation
                        cep={cep}
                        address={address}
                        addressNumber={addressNumber}
                        city={city}
                        state={state}
                        neighborhood={neighborhood}
                        addressComplement={addressComplement}
                        errorMessage={errorMessage}
                        display={displayFormSendRegistrationLocation}
                        displayErrorMessage={displayErrorMessage}
                        onBlurSearchCep={this.onBlurSearchCep}
                        handleChange={this.handleChangeFormSendRegistrationLocation}
                        handleSubmit={this.handleSubmitFormSendRegistrationLocation}
                        onCloseErrorMessage={this.onCloseErrorMessage}
                        onClickReturnFormSendRegistrationData={this.onClickReturnFormSendRegistrationData}
                    />

                    <FormSendRegistrationLogin
                        email={email}
                        confirmEmail={confirmEmail}
                        password={password}
                        errorMessage={errorMessage}
                        display={displayFormSendRegistrationLogin}
                        displayErrorMessage={displayErrorMessage}
                        handleChange={this.handleChangeFormSendRegistrationLogin}
                        handleSubmit={this.handleSubmitFormSendRegistrationLogin}
                        onCloseErrorMessage={this.onCloseErrorMessage}
                        onClickReturnFormSendRegistrationLocation={this.onClickReturnFormSendRegistrationLocation}
                    />

                    <FormSuccessNewSubscriptionProducts
                        email={email}
                        display={displayFormSuccessNewSubscriptionProducts}
                        displayErrorMessage={displayErrorMessage}
                        handleSubmitFormSendRegistrationLogin={this.handleSubmitFormSendRegistrationLogin}
                        onCloseErrorMessage={this.onCloseErrorMessage}
                    />

                </ModalTemplate>
            </>
        )
    }
}