import React from 'react'

export default (props) => {
    return props.rows.map((response, key) => (
        <div className="card" key={key}>
            <p style={{ textTransform: 'uppercase' }}>
                <strong>{response.productName}</strong>
            </p>
            <p style={{ textTransform: 'uppercase' }}>PLANO COMPLETO</p>
            {/* <p><strong>{response.beneficiaries}</strong></p> */}
            <p>Vigência: <strong>{response.createData}</strong></p>
            <span className="status" style={{ textTransform: 'uppercase' }}>status: ativo</span>
            {/* <a href="/#" style={{ textTransform: 'uppercase' }}>+ Detalhes</a> */}
        </div>
    ))
};