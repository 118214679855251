import React from 'react'
import PasswordMask from 'react-password-mask'
import ErrorMessage from '../../../../Errors'

export default (props) => {
    return (
        <section className="stage" style={{ display: `${(props.display) ? 'block' : 'none'}` }}>
            <div className="bloc-stage">
                <form className="rec-pass" onSubmit={props.handleSubmit}>
                    <PasswordMask
                        name="password"
                        id="password"
                        placeholder="Digite uma nova senha"
                        value={props.password}
                        onChange={props.handleChange}
                        showButtonContent={'Exibir'}
                        hideButtonContent={'Ocultar'}
                    />
                    <PasswordMask
                        name="confirmPassword"
                        id="confirmPassword"
                        placeholder="Digite a senha novamente"
                        value={props.confirmPassword}
                        onChange={props.handleChange}
                        showButtonContent={'Exibir'}
                        hideButtonContent={'Ocultar'}
                    />
                    <ErrorMessage 
                        display={props.displayErrorMessage} 
                        onCloseErrorMessage={(e) => props.onCloseErrorMessage(e)} 
                        message={props.errorMessage} 
                    />
                    <button anime="bg-transition" bt="filled">Criar nova senha</button>
                </form>
            </div>
        </section>
    );
}