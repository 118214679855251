import React from 'react'
// import NumberFormat from 'react-number-format'
import ErrorMessage from '../../../../Errors'

export default (props) => {
    return (
        <section className="stage" style={{ display: `${(props.display) ? 'block' : 'none'}` }}>
            <div className="bloc-stage">
                {/* <p>Digite o número <strong>VIVO Fixo</strong> associado ao seu seguro</p> */}
                <p>Digite o e-mail associado ao seu seguro:</p>
                <form className="rec-pass" onSubmit={props.handleSubmit}>
                    {/* <NumberFormat
                        value={props.phone}
                        onChange={props.handleChange}
                        type="text"
                        name="phone"
                        placeholder="Número de telefone VIVO"
                        required
                        format="(##) #####-####" mask="_"
                        autoFocus={true}
                    /> */}
                    <input
                        type="email"
                        name="email"
                        value={props.email}
                        onChange={props.handleChange}
                        placeholder="Digite seu e-mail"
                        required
                    />
                    <ErrorMessage
                        display={props.displayErrorMessage}
                        onCloseErrorMessage={(e) => props.onCloseErrorMessage(e)}
                        message={props.errorMessage}
                    />
                    <button anime="bg-transition" bt="filled">Continuar</button>
                </form>
            </div>
        </section>
    );
}