import React, { Component } from 'react'
import Loading from '../../Loading'
import { 
    validateToken, 
    removeToken
} from '../../../utils'

export default class ButtonLogout extends Component {

    constructor(){
        super();

        this.state = {
            loading: false
        }
    }

    /**
     * Handle Click Logout
     */
    handleLogoutClick(e) {
        e.preventDefault();

        if(validateToken('token')){
            this.setState({ 
                loading: true
            });

            setTimeout(function () {
                removeToken();
            }, 3000);
        }
    }

    /**
     * Render Component
     */
    render() {
        const { loading } = this.state;
        return (
            <>
                <Loading display={loading} />
                <a href="/#" onClick={(e) => this.handleLogoutClick(e)}>Sair</a>
            </>
        );
    }
}