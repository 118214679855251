import axios from 'axios'

import {
    validateToken
} from '../../utils'

import {
    URL_BACKEND,
    BRAND_NAME,
    REQUESTER_NAME
} from '../../constants'

const HEADERS = {
    'Content-Type': 'application/json',
    'X-FS-Requester-Name': REQUESTER_NAME,
    'X-FS-Brand-Name': BRAND_NAME
}

const HEADERS1 = {
    'X-FS-Requester-Name': REQUESTER_NAME,
    'X-FS-Brand-Name': BRAND_NAME
}

/**
 * Function Send New User
 */
export async function sendCreateUserWithSale(body) {
    try {
        await axios.post(`${URL_BACKEND}/bff/api/v2/insurance/subscribe`, body, { headers: HEADERS });
        return { status: true };
    } catch (error) {

        if(error.response.status === 401){
            return { status: 'info', message: `Ops, o cliente já possui o produto selecionado! Tente novamente!` };
        }  

        if (error.request.status === 406) {
            return { status: false, message: `Ops, este email já está sendo utilizado. Tente novamente!` };
        }

        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
        }

        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
        }

        return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
    }
}

/**
 * Function Get Sale Validate
 */
export async function getSaleValidate(extref) {
    try {
        const response = await axios.get(`${URL_BACKEND}/bff/api/v2/insurance/policy/search/${extref}`, { headers: HEADERS });
        return {
            status: true,
            productName: response.data.bundle.name,
            price: response.data.bundle.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
        };
    } catch (error) {

        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
        }

        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
        }

        return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
    }
}

/**
 * Function Send Sale Validate
 */
export async function sendSaleValidate(body) {
    try {
        await axios.put(`${URL_BACKEND}/bff/api/v2/insurance/policy/activation`, body, { headers: HEADERS });
        return { status: true };
    } catch (error) {

        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
        }

        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
        }

        return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
    }
}

/**
 * Function Send Change Password By Token
 * @param {object} body 
 * @param {int} hash 
 */
export async function sendChangePasswordByToken(body, hash) {
    try {
        await axios.post(`${URL_BACKEND}/bff/api/v2/insurance/changepassword/${hash}`, body, { headers: HEADERS });
        return { status: true };
    } catch (error) {

        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
        }

        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
        }

        return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
    }
}

/**
 * Function Send Get Sales By User
 */
export async function sendGetSalesByUser() {
    try {
        HEADERS1['Authorization'] = `Bearer ${validateToken('token')}`;
        const response = await axios.get(`${URL_BACKEND}/bff/api/v2/insurance/client/search/email/${validateToken('email')}`, { headers: HEADERS1 });

        const productsActive = [];
        const productsInactive = [];

        response.data.data.forEach(response => {
            response.policies.forEach(response => {
                if (response.actived === 'ACTIVE' || response.actived === 'PENDING') {
                    productsActive.push({
                        bid_id: response.id,
                        canceledNotificationDate: response.bundle.deleted_at ? response.bundle.deleted_at : '',
                        createData: response.bundle.created_at,
                        firstPayment: '',
                        productName: response.bundle.name,
                        value: response.bundle.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                    });
                }

                if (response.actived === 'CANCELED') {
                    productsInactive.push({
                        bid_id: response.id,
                        canceledNotificationDate: response.bundle.deleted_at ? response.bundle.deleted_at : '',
                        createData: response.bundle.created_at,
                        firstPayment: '',
                        productName: response.bundle.name,
                        value: response.bundle.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                    });
                }                
            });
        });

        return {
            status: true,
            productsActive: productsActive,
            productsInactive: productsInactive
        };

    } catch (error) {

        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
        }

        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
        }

        return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
    }
}

/**
 * Function Send Request Forgot Password
 */
export async function sendRequestForgotPassword(body) {
    try {
        await axios.post(`${URL_BACKEND}/bff/api/v2/insurance/forgotpassword`, body, { headers: HEADERS });
        return { status: true };
    } catch (error) {

        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
        }

        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
        }

        return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
    }
}

/**
 * Function Send Email Support
 */
export async function sendEmailSupport(body, token) {
    try {
        await axios.post(`${URL_BACKEND}/bff/api/v2/insurance/mapfre/email`, body, {
            headers: {
                'X-FS-Requester-Name': REQUESTER_NAME,
                'X-FS-Brand-Name': BRAND_NAME,
                'X-Recaptcha-Response': token
            }
        });
        return { status: true };
    } catch (error) {

        if (error.response.data.code === 406) {
            return { status: false, code: 406, message: `Máximo de tentativas excedido. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
        }

        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
        }

        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
        }

        return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
    }
}