import React from 'react'
import { Link } from 'react-router-dom'

export default (props) => {
    return (
        <nav className="nav">
            <div className="logo">
                <h1>
                    <a href="/">
                        <img src="assets/images/logo/logo-vivo-branco.png" className="logo-vivo" alt="Logo VIVO" />
                        <img src="assets/images/logo/logo-mapfre.svg" className="logo-mapfre" alt="Logo Mapfre" />
                    </a>
                </h1>
            </div>
            <div className="menu-primary">
                <ul className="menu">
                    <li>
                        <a href="/" className={`${props.activeMenu1} a-transition`}>Inicio</a>
                    </li>
                    <li>
                        <a href="/seguro-residencial" className={`${props.activeMenu2} a-transition`}>Seguro Residencial</a>
                    </li>
                    <li>
                        <a href="/conta-protegida" className={`${props.activeMenu3} a-transition`}>Conta Protegida</a>
                    </li>
                    <li>
                        <Link to="login" className="a-transition"><i className="fas fa-user a-transition active" /></Link>
                    </li>
                    <li className="close-menu-mobile">
                        <span className="a-transition"><i className="fas fa-times" /> Fechar menu</span>
                    </li>
                </ul>
                <div className="menu-mobile">
                    <i className="fas fa-bars" />
                </div>
            </div>
        </nav>
    )
}