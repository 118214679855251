import React, { Component } from 'react'
import queryString from 'query-string'

import {
    validatorPassword,
    validatorIdenticalPassword
} from '../../../utils'

import {
    sendChangePasswordByToken
} from '../../../services'

import ModalTemplate from '../Template'
import FormSendPassword from '../../Forms/Modal/NewPassword/SendPassword'
import FormSuccessPassword from '../../Forms/Modal/NewPassword/SuccessPassword'
import Loading from '../../Loading'

export default class ModalNewPassword extends Component {

    /**
     * Constructor
     */
    constructor() {
        super();

        this.state = {
            loading: false,
            errorMessage: null,
            displayHeader: true,
            displayErrorMessage: false,
            displayModal: false,
            displayFormSendNewPassword: true,
            displayFormSuccessNewPassword: false,
            password: '',
            confirmPassword: '',
            hash: ''
        }

        this.baseState = this.state;
        this.onCloseErrorMessage = this.onCloseErrorMessage.bind(this);
        this.handleChangeFormSendNewPassword = this.handleChangeFormSendNewPassword.bind(this);
        this.handleSubmitFormSendNewPassword = this.handleSubmitFormSendNewPassword.bind(this);
    }

    /**
     * Component Did Mount
     */
    componentDidMount(){  
        const params = queryString.parse(window.location.search);
        if(params.resetPassword){
            this.setState({
                displayModal: true,
                hash: params.resetPassword
            });
        }
    }

    /**
     * On Click Modal Display
     */
    onClickModalDisplay = (status, e) => {
        e.preventDefault();
        if (status) {
            this.setState({ displayModal: true });
            return;
        }

        this.setState(this.baseState);
        return;
    }

    /**
     * On Close Error Message
     */
    onCloseErrorMessage = (e) => {
        this.setState({
            displayErrorMessage: false,
            errorMessage: null
        });
    }

    /**
     * Handle Change Form Send New Password
     * @param {event} e 
     */
    handleChangeFormSendNewPassword(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    /**
     * Handle Submit Form Send New Password
     * @param {event} e 
     */
    async handleSubmitFormSendNewPassword(e) {
        e.preventDefault();

        const { password, confirmPassword, hash } = this.state;

        const validator1 = validatorPassword(password);

        if (!validator1.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: validator1.message
            });
            return;
        }

        const validator2 = validatorIdenticalPassword(password, confirmPassword);

        if (!validator2.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: validator2.message
            });
            return;
        }

        this.setState({
            loading: true,
            displayErrorMessage: false,
            errorMessage: null
        });

        const response = await sendChangePasswordByToken({
            password: validator1.password
        }, hash);

        if (!response.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: response.message
            });
            return;
        }

        this.setState({
            loading: false,
            password: validator1.password,
            displayFormSendNewPassword: false,
            displayFormSuccessNewPassword: true
        });
    }

    /**
     * Render Component
     */
    render() {
        const {
            loading,
            errorMessage,
            displayErrorMessage,
            displayModal,
            displayFormSendNewPassword,
            displayFormSuccessNewPassword,
            password,
            confirmPassword
        } = this.state;

        return (
            <>
                <ModalTemplate
                    display={displayModal}
                    headerTitle={'Criar nova senha'}
                    onClickModalDisplay={this.onClickModalDisplay}
                >
                    <Loading display={loading}/>
                    
                    <FormSendPassword
                        password={password}
                        confirmPassword={confirmPassword}
                        display={displayFormSendNewPassword}
                        errorMessage={errorMessage}
                        displayErrorMessage={displayErrorMessage}
                        handleChange={this.handleChangeFormSendNewPassword}
                        handleSubmit={this.handleSubmitFormSendNewPassword}
                        onCloseErrorMessage={this.onCloseErrorMessage}                        
                    />

                    <FormSuccessPassword 
                        display={displayFormSuccessNewPassword}
                        onClickModalDisplay={this.onClickModalDisplay}
                    />
                </ModalTemplate>
            </>
        )
    }
}