import React, { Component } from 'react'
import Menu from '../../components/Menu/External'
import Footer from '../../components/Footer'

export default class Home extends Component {

    /**
     * Render Component
     */
    render() {
        return (
            <main className="main">
                <section className="banner">
                    <div className="center-content">
                        <Menu activeMenu1={'active'} />
                        <section className="infos-banner">
                            <h1>SEGURANÇA E COMODIDADE PARA VOCÊ E SUA FAMÍLIA!</h1>
                            <p>Conheça o Seguro MAPFRE Proteção Residencial VIVO e MAPFRE Conta Protegida VIVO e fique tranquilo em todos os momentos. O Seguro que você paga através da sua conta de Telefone Vivo Fixa.</p>
                        </section>
                        <img src="assets/images/bg/personagens.png" className="personagem-banner" alt="Personagens" />
                    </div>
                </section>
                <section className="seguros">
                    <div className="center-content">
                        <div className="center-content-block">
                            <section className="card">
                                <div className="infos">
                                    <p className="name">Seguro Residencial</p>
                                    <div className="price">
                                        <p>Planos a partir de</p>
                                        <div className="val-price">R$6,90<small>/mensais</small></div>
                                    </div>
                                    <a href="/seguro-residencial#tabela-planos" className="bt a-transition">
                                        Conheça todos os planos
                                        <span className="a-transition"><i className="fas fa-arrow-right" /></span>
                                    </a>
                                </div>
                                <div className="items">
                                    <ul className="list-items">
                                        <li>
                                            <img src="assets/images/icons/incendio.png" alt="Ícone Incêndio" />
                                            <p>Incêndio, queda de raio e explosão de qualquer natureza</p>
                                        </li>
                                        <li>
                                            <img src="assets/images/icons/danos-eletricos.png" alt="Ícone Danos ELétricos" />
                                            <p>Danos elétricos</p>
                                        </li>
                                        <li>
                                            <img src="assets/images/icons/roubo-bens.png" alt="Ícone Roubo de Bens" />
                                            <p>Roubo de bens</p>
                                        </li>
                                        <li>
                                            <img src="assets/images/icons/reparos-tecnicos.png" alt="Ícone Reparos Técnicos" />
                                            <p>Reparos técnicos</p>
                                        </li>
                                        <li>
                                            <img src="assets/images/icons/fenomenos_naturais.png" alt="Ícone Fenômenos Naturais" />
                                            <p>Fenômenos Naturais</p>
                                        </li>
                                        <li>
                                            
                                        </li> 
                                    </ul>
                                </div>
                            </section>
                            <div className="spacer">
                                <span className="block" />
                                <hr />
                            </div>
                            <section className="card">
                                <div className="infos">
                                    <p className="name">Seguro Conta Protegida</p>
                                    <div className="price">
                                        <p>Planos a partir de</p>
                                        <div className="val-price">R$5,90<small>/mensais</small></div>
                                    </div>
                                    <a href="/conta-protegida#tabela-planos" className="bt a-transition">
                                        Conheça todos os planos
                                        <span className="a-transition"><i className="fas fa-arrow-right" /></span>
                                    </a>
                                </div>
                                <div className="items">
                                    <ul className="list-items">
                                        <li>
                                            <img src="assets/images/icons/roubo-bens-2.png" alt="Ícone Roubo Bens" />
                                            <p>Desemprego involuntário</p>
                                        </li>
                                        <li>
                                            <img src="assets/images/icons/incapacidade.png" alt="Ícone Incapacidade" />
                                            <p>Incapacidade física temporária por acidente</p>
                                        </li>
                                        <li>
                                            <img src="assets/images/icons/invalidez.png" alt="Ícone Invalidez" />
                                            <p>Invalidez permanente total por acidente</p>
                                        </li>
                                        <li>
                                            <img src="assets/images/icons/recolocacao.png" alt="Ícone Recolocação" />
                                            <p>Assistência recolocação profissional</p>
                                        </li>
                                        <li>
                                            <img src="assets/images/icons/morte.png" alt="Ícone Morte" />
                                            <p>Morte</p>
                                        </li>
                                        <li>
                                            <p />
                                        </li>
                                    </ul>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
                <div className="person-consultant">
                    <div className="center-content">
                        <img src="assets/images/icons/consultor.png" alt="" />
                    </div>
                </div>
                <Footer />
            </main>
        );
    }
}