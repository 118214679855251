import React, { Component } from 'react'
import Menu from '../../components/Menu/External'
import Footer from '../../components/Footer'
import ModalSelectMethodPurchase from '../../components/Modal/SelectMethodPurchase'
import { PRODUCT_PROTECTED_ACCOUNT } from '../../constants'

export default class ProtectedAccountInsurance extends Component {

    /**
     * Function Products List
     */
    productsList = () => {
        const products = PRODUCT_PROTECTED_ACCOUNT;

        return products.map((response, key) => {
            return <div className="plan-card" key={key}>
                <div className="header-card">
                    <p>Plano {response.plan}</p>
                </div>
                <div className="legend-plan no-item">
                    <p>Importância Segurada</p>
                </div>
                <div className="item-plan no-item">
                    <p className="title-item-plan">Incêndio, Raio, Explosão e Queda de Aeronaves</p>
                    <div className="englob-itens">
                        <p>{response.coverage_1}</p>
                    </div>
                </div>
                <div className="item-plan no-item">
                    <p className="title-item-plan">Roubo e Furto de Bens Mediante Arrombamento</p>
                    <div className="englob-itens">
                        <p>{response.coverage_2}</p>
                    </div>
                </div>
                <div className="item-plan no-item">
                    <p className="title-item-plan">Danos Elétricos</p>
                    <div className="englob-itens">
                        <p>{response.coverage_3}</p>
                    </div>
                </div>
                <div className="item-plan no-item">
                    <p className="title-item-plan">Fenômenos Naturais</p>
                    <div className="englob-itens">
                        <p>{response.coverage_4}</p>
                    </div>
                </div>
                <div className="item-plan no-item">
                    <p className="title-item-plan">Assistências Personalizadas</p>
                    <div className="englob-itens">
                        <p>{response.coverage_5}</p>
                    </div>
                </div>
                <div className="price-plan">
                    <p>{response.price}/mês</p>
                </div>
                <ModalSelectMethodPurchase
                    productId={response.product_id}
                />
            </div>
        })
    }

    /**
     * Function Products Card
     */
    productsCard = () => {
        const products = PRODUCT_PROTECTED_ACCOUNT;

        return products.map((response, key) => {
            return <div className="card-tabela" key={key}>
                        <div className="title-card">
                            <p>Plano {response.plan}</p>
                        </div>

                        <div className="opt-card">
                            <small>Morte</small>
                            <p>{response.coverage_1}</p>
                            <br />
                            <small>Invalidez permanente total por acidente</small>
                            <p>{response.coverage_2}</p>
                            <br />
                            <small>Desemprego involuntário</small>
                            <p>{response.coverage_3}</p>
                            <br />
                            <small>Incapacidade física temporária por acidente</small>
                            <p>{response.coverage_4}</p>
                            <br />
                            <small>Assistência a recolocação profissional</small>
                            <p>{response.coverage_5}</p>
                        </div>

                        <div className="price-card">
                            <p>{response.price}/mês</p>
                        </div>

                        <ModalSelectMethodPurchase 
                            productId={response.product_id}
                        />
                    </div>
        })
    }

    /**
     * Render Component
     */
    render() {
        return (
            <main className="main">
                <section className="banner banner-conta-protegida">
                    <div className="center-content interna-residencial">
                        <Menu activeMenu3={'active'} />
                        <div className="info-banner-interno">
                            <p className="_title-interno">Seguro</p>
                            <p className="_title-interno">Conta Protegida</p>
                            <p style={{ maxWidth: 450, justifyContent: 'center', marginTop: 15, color: 'var(--secundary)', fontSize: '1.1rem', letterSpacing: '.04rem', lineHeight: '1.7rem' }}>
                                Você cliente Vivo Fixa agora pode contar com o Seguro MAPFRE Conta Protegida Vivo nos casos de imprevistos do dia a dia.
                            </p>
                        </div>
                    </div>
                </section>
                <section className="seguros page-interna">
                    <div className="center-content reset-padding-bottom">
                        <div className="center-content-block">
                            <div className="bloc-interna">
                                <h2>Benefícios do Conta Protegida</h2>

                                <p>O MAPFRE Seguro Conta Protegida VIVO é um produto desenvolvido com a parceria da MAPFRE e da Vivo, para trazer tranquilidade a você e sua família nos imprevistos.</p>

                                <p>Contratando MAPFRE Seguro Conta Protegida VIVO, você terá as coberturas de Morte, Invalidez Permanente Total por Acidente e Incapacidade Física Temporária por Acidente cobertura exclusiva para Autônomos ou Desemprego Involuntário do titular para profissionais CLT, além das coberturas do seguro, você pode contar ainda com uma Assistência Recolocação Profissional para lhe ajudar com dicas de como se portar em uma entrevista, como aumentar o seu Network, Revisão de Currículo, entre outros.</p>

                                <div className="beneficios">
                                    <p className="title-beneficios">Conheça os benefícios</p>

                                    <img src="assets/images/icons/assessor-virtual.png" alt="Ícone Incêndio" style={{ maxWidth: 60, marginTop: 20, marginBottom: 10 }} />
                                    <p style={{ marginBottom: 50 }}>Assessor Virtual</p>

                                    <a href="#tabela-planos" className="bt a-transition">
                                        Conheça todos os planos
                                        <span className="a-transition"><i className="fas fa-arrow-right" /></span>
                                    </a>
                                </div>
                            </div>

                            <div className="spacer">
                                <span className="block" />
                                <hr />
                            </div>

                            <div className="bloc-interna">
                                <h2>Como funciona?</h2>

                                <ul className="list-options-interna">
                                    <li>1. Escolha um dos planos;</li>
                                    <li>2. Preencha seus dados;</li>
                                    <li>3. Informe o número do telefone da Linha Fixa;</li>
                                    <li>4. Pague as mensalidades através da conta de telefone da fixa;</li>
                                    <li>5. Receba seu Certificado de Seguro.</li>
                                </ul>
                            </div>

                            <div className="bloc-interna">
                                <h2>Vantagens</h2>

                                <p>Com o seu Seguro MAPFRE Conta Protegida Vivo, você garante o uso do seu telefone e acesso a internet em caso de perda do emprego e ainda conta com um assessor virtual que lhe dará muitas dicas para sua recolocação, de acordo com o plano escolhido.</p>

                                <p>Escolha agora o plano que mais combina com você! </p>

                                <p>E o melhor, o pagamento do seguro é cobrado direto na sua conta de telefone da fixa mensalmente proporcionando mais facilidades.</p>
                            </div>

                        </div>
                    </div>
                    <div className="center-tabela">
                        <div className="tabela-planos" id="tabela-planos">
                            <div className="plan-card legendas">
                                <div className="header-card" style={{ opacity: "0" }}>
                                    <p>.</p>
                                </div>

                                <div className="legend-plan" style={{ justifyContent: "center", textTransform: "uppercase" }}>
                                    <p style={{ padding: "0 1rem" }}>Coberturas</p>
                                </div>

                                <div className="item-plan" style={{ justifyContent: "center", textAlign: "center" }}>
                                    <p style={{ padding: "0 1rem" }}>Morte</p>
                                </div>

                                <div className="item-plan" style={{ justifyContent: "center", textAlign: "center" }}>
                                    <p style={{ padding: "0 1rem" }}>Invalidez permanente total por acidente </p>
                                </div>

                                <div className="item-plan" style={{ justifyContent: "center", textAlign: "center" }}>
                                    <p style={{ padding: "0 1rem" }}>Desemprego involuntário</p>
                                </div>

                                <div className="item-plan" style={{ justifyContent: "center", textAlign: "center" }}>
                                    <p style={{ padding: "0 1rem" }}>Incapacidade física temporária por acidente</p>
                                </div>

                                <div className="item-plan" style={{ justifyContent: "center", textAlign: "center" }}>
                                    <p style={{ padding: "0 1rem" }}>Assistência a recolocação profissional</p>
                                </div>

                                <div className="item-plan no-item" style={{ opacity: "0" }}>
                                    <p>.</p>
                                </div>

                                <div className="price-plan" style={{ opacity: "0" }}>
                                    <p>.</p>
                                </div>

                                <a href="/" className="bt-plan" style={{ opacity: "0" }}>
                                    <p>.</p>
                                </a>
                            </div>
                            {this.productsList()}
                        </div>

                        <div className="cards-tabela">
                            {this.productsCard()}
                        </div>

                        <div className="txt-info-table center-content-block">
                            <p><strong>NOTAS:</strong></p>

                            <p>As Coberturas do Seguro Conta Protegida MAPFRE Vivo são válidas ao titular da linha cadastrado da linha fixa.</p>
                            <br />

                            <p><strong>1) Desemprego Involuntário</strong></p>
                            <p>Vínculo empregatício com o mesmo empregador por 12 mêses</p>
                            <p>Carência* [da vigência] - 31 dias</p>
                            <p>Franquia** [do evento] - 31 dias</p>
                            <br />
                            <p><strong>2) Incapacidade Física Temporária por Acidente</strong></p>
                            <p>Elegibilidade: Incapaticitado de exercer sua função por período maior que 15 dias seguidos.</p>
                            <p>Carência* [da vigência] - Não há</p>
                            <p>Franquia** [do evento] -15 dias</p>
                            <br />
                            <p><strong>3) Assistência Recolocação Profissional</strong></p>
                            <p>Em caso Elegibilidade de demissão sem justa causa ou término do contrato de proteção.</p>
                        </div>
                    </div>

                    <div className="center-content reset-padding-top">
                        <div className="center-content-block">
                            <div className="bloc-interna">
                                <h2>Dúvidas</h2>

                                <details open>
                                    <summary>Qual o objetivo do Seguro Conta Protegida?</summary>
                                    <div className="content-details">
                                        <p>Garantir o pagamento de uma indenização ao segurado ou ao(s) beneficiário(s) caso ocorra algum dos eventos cobertos pelas garantias contratadas, observadas as condições contratuais.</p>
                                    </div>
                                </details>

                                <details>
                                    <summary>Quem pode ser segurado?</summary>
                                    <div className="content-details">
                                        <p>Serão elegíveis todas as pessoas físicas clientes da Telefônica/Vivo, modalidade de telefonia fixa, com idade mínima de 18 (dezoito) e máxima de 65 (sessenta e cinco) anos 11(onze) meses e 29(vinte e nove) dias.</p>
                                    </div>
                                </details>

                                <details>
                                    <summary>Quem é o Estipulante?</summary>
                                    <div className="content-details">
                                        <p>Pessoa jurídica que propõe a contratação de plano coletivo com poderes para a representação do segurado nos termos da legislação e regulação em vigor, sendo identificado como estipulante-instituidor quando participar, total ou parcialmente, do custeio do plano e como estipulante-averbador quando não participar do custeio. "Neste caso, o Estipulante do seguro é a Telefônica/Vivo.”</p>
                                    </div>
                                </details>

                                <details>
                                    <summary>O que é prêmio do seguro?</summary>
                                    <div className="content-details">
                                        <p>É o valor pago pelo Segurado para que a Seguradora possa garantir a cobertura da apólice.</p>
                                    </div>
                                </details>

                                <details>
                                    <summary>Quem é o corretor de seguros?</summary>
                                    <div className="content-details">
                                        <p>As corretoras de seguros responsáveis pelo produto Conta Protegida são TGP Brasil Corretora e Administradora de Seguros e Telefônica Corretora de Seguros Ltda.</p>
                                    </div>
                                </details>

                                <details>
                                    <summary>Quais são as coberturas do Seguro Conta Protegida? </summary>
                                    <div className="content-details">
                                        <p>O seguro MAPFRE Seguro Conta Protegida VIVO é destinado aos clientes da Telefônica/Vivo, telefonia fixa e proporciona ao segurado e aos seus familiares tranquilidade na hora do imprevisto.</p>

                                        <p>Coberturas:</p>

                                        <p><strong>MORTE</strong>: garante um crédito (1) único, conforme plano contratado, indicado no certificado individual do seguro, para a sua família pagar as contas de telefone, em caso de morte do segurado em decorrência de doença ou acidente, durante a vigência do seguro, observadas as restrições e demais condições constantes nas Condições Gerais e no Contrato de Seguro.  </p>

                                        <p><strong>INVALIDEZ PERMANENTE TOTAL POR ACIDENTE (IPTA)</strong>: garante um crédito (1) único, conforme plano contratado, indicado no certificado individual de seguro, para pagar as contas de telefone do próprio segurado, caso ele venha a se tornar total e permanentemente inválido em decorrência direta e exclusiva de acidente coberto durante a vigência do seguro, observadas as restrições e demais condições constantes nas Condições Gerais e no Contrato de Seguro. Para efeito deste seguro, entende-se como "Invalidez Permanente Total", os acidentes que resultem em:</p>

                                        <p>1. Perda total da visão de ambos os olhos.</p>

                                        <p>2. Perda total do uso de ambos os braços.</p>

                                        <p>3. Perda total do uso de ambas as pernas.</p>

                                        <p>4. Perda total do uso de ambas as mãos.</p>

                                        <p>5. Perda total do uso de um braço e uma perna.</p>

                                        <p>6. Perda total do uso de uma das mãos e um dos pés.</p>

                                        <p>7. Perda total do uso de ambos os pés.</p>

                                        <p>8. Alienação mental total e incurável.</p>

                                        <p>9. Nefrectomia bilateral.</p>

                                        <p>O pagamento de qualquer indenização por invalidez permanente total por acidente estará condicionado à constatação de invalidez permanente, ou seja, após conclusão do tratamento do segurado (ou esgotados os recursos terapêuticos para recuperação) e verificada a existência de invalidez permanente avaliada quando da alta médica definitiva, com o(s) grau(s) e tipos de invalidez definitivamente caracterizados e mediante diagnóstico médico final a ser apresentado pelo segurado. </p>

                                        <p>- <strong>DESEMPREGO INVOLUNTÁRIO (Profissionais CLT)</strong>: garante 1 (um) crédito único, conforme plano contratado, indicado no certificado individual de seguro, para pagamento da conta Vivo do Segurado, em caso de perda involuntária de emprego do Segurado durante a vigência do seguro, observadas as restrições e demais condições constantes nas Condições Gerais e no Contrato de Seguro. Como "perda de renda por desemprego involuntário" entende-se o trabalhador segurado que ficar desempregado involuntariamente, desde que a demissão não tenha sido por justa causa, e que fique sem receber remuneração alguma pela prestação de um trabalho pessoal para outro empregador. Elegibilidade: serão elegíveis todas as pessoas físicas que possuam vínculo com o estipulante, que possuam vínculo empregatício, com carteira de trabalho assinada em conformidade com a Consolidação das Leis do Trabalho, comprovando um período mínimo de 12 (doze) meses de trabalho ininterrupto para um mesmo empregador, com uma jornada de trabalho mínima de 30 (trinta) horas semanais na data do evento e que atendam as condições de aceitação do seguro. Carência: será aplicada uma carência de 31 (trinta e um) dias ininterruptos contatos a partir das 24 (vinte e quatro) horas de início de vigência do seguro indicada no certificado, desde que o respectivo prêmio tenha sido pago. Franquia: será aplicada uma franquia de 31 (trinta e um) dias ininterruptos contados a partir da data da ocorrência do evento coberto.</p>

                                        <p>- <strong>INCAPACIDADE FÍSICA TEMPORÁRIA POR ACIDENTE (Profissionais liberais ou autônomos)</strong>: garante 1 (um) crédito único, conforme plano contratado, indicado no certificado individual de seguro, para pagamento da conta Vivo do segurado, em caso de incapacidade física temporária do segurado decorrente, exclusivamente, de acidente pessoal coberto, durante a vigência do seguro, observadas as restrições e demais condições constantes nas Condições Gerais e no Contrato de Seguro. Como "perda de renda por incapacidade física temporária", entende-se aquela pela qual o segurado fique temporariamente impedido de exercer sua atividade remunerativa habitual por um período igual ou superior a 15 (quinze) dias consecutivos. Elegibilidade: serão elegíveis todas as pessoas físicas, profissionais liberais ou autônomos que possam comprovar a atividade exercida através dos documentos relacionados no item LIQUIDAÇÃO DE SINISTROS deste manual, que possuam vínculo com o estipulante e que atendam as condições de aceitação do seguro. Carência: não há. Franquia: será aplicada uma franquia de 15 (quinze) dias ininterruptos contados a partir da data da ocorrência do evento coberto.</p>
                                    </div>
                                </details>

                                <details>
                                    <summary>O que é carência e franquia?</summary>
                                    <div className="content-details">
                                        <p><strong>Carência:</strong> período ininterrupto de dias contado a partir da data de início de vigência do seguro durante o qual, na ocorrência do evento coberto, o segurado ou o(s) beneficiário(s) não terão direito à percepção dos capitais segurados contratados. Para sinistros decorrentes de acidentes pessoais todos os prazos de carência não serão aplicados, exceto no caso de suicídio ou sua tentativa, quando a referida carência corresponderá a 02 (dois) anos ininterruptos, contados da data de contratação ou de adesão ao seguro, ou de sua recondução depois de suspenso. </p>

                                        <p><strong>Franquia:</strong> período contínuo de tempo, contado a partir da data da ocorrência do evento coberto, durante o qual o segurado não terá direito à cobertura do seguro.</p>
                                    </div>
                                </details>

                                <details>
                                    <summary>Qual Carência e Franquia do Seguro Conta Protegida?</summary>
                                    <div className="content-details">
                                        <p><strong>Coberturas de Morte e Invalidez Permanente Total por Acidente:</strong></p>

                                        <p>- Carência - não há.</p>
                                        <p>- Franquia - não.</p>

                                        <p><strong>Cobertura de Desemprego Involuntário:</strong></p>

                                        <p>- Carência (da vigência) - 31 dias.</p>
                                        <p>- Franquia (do evento) - 31 dias.</p>

                                        <p><strong>Incapacidade Temporária por Acidente:</strong></p>

                                        <p>- Carência - não há.</p>

                                        <p>- Franquia - 15 dias.</p>
                                    </div>
                                </details>

                                <details>
                                    <summary>Quando inicia a cobertura do seguro?</summary>
                                    <div className="content-details">
                                        <p>O início de cobertura do seguro será a partir das 24 horas da data de vencimento estipulada na fatura telefônica, desde que haja a quitação da mesma.</p>
                                    </div>
                                </details>

                                <details>
                                    <summary>Como será realizada a cobrança do seguro?</summary>
                                    <div className="content-details">
                                        <p>O seguro será cobrado mensalmente na Fatura Telefônica.</p>
                                    </div>
                                </details>

                                <details>
                                    <summary>Como cancelo o meu seguro?</summary>
                                    <div className="content-details">
                                        <p>Há duas formas de cancelar o Seguro:</p>
                                        <p>1) Neste site, através dá área MINHA CONTA, basta utilizar seu login e senha, caso ainda não tenha, você pode se cadastrar ou;</p>
                                        <p>2) Através da Central de Relacionamento no telefone 4002-7041(capital e regiões metropolitanas) e 0800 570 7041(demais localidades) ou pelo SAC 0800 570 7045. Deficientes Auditivos e de Fala 0800 775 5045.</p>
                                    </div>
                                </details>

                                <details>
                                    <summary>Quem garante as coberturas do meu seguro?</summary>
                                    <div className="content-details">
                                        <p>MAPFRE SEGUROS GERAIS S.A., inscrita no CNPJ 61.074.175/0001-38, de acordo com a Nota Técnica e respectivas Condições Gerais aprovadas pela Superintendência de Seguros Privados – SUSEP, conforme Processo nº. 15414.100431/2002-51.</p>

                                        <p>MAPFRE CAPITALIZAÇÃO S.A., inscrita no CNPJ 09.382.998/0001-00, de acordo com a Nota Técnica e respectivas Condições Gerais aprovadas pela Superintendência de Seguros Privados - SUSEP conforme Processo nº. 15414.000959/2008-16.</p>
                                    </div>
                                </details>

                            </div>

                            <div className="bloc-interna">
                                <h2>Condições Gerais</h2>

                                <ul className="list-options-interna">
                                    <li><a href="assets/pdf/conta-protegida/manual-do-segurado.pdf">Manual do Segurado</a></li>
                                    <li><a href="assets/pdf/conta-protegida/CG V.2.1 01032018 a atual.pdf">Vigência: 02/02/2016 – em vigor atualmente</a></li>
                                    <li><a href="assets/pdf/conta-protegida/CG-Prestamista-v20 04042014 A 01022016.pdf">Vigência: 04/04/2014 até 01/02/2016</a></li>
                                    <li><a href="assets/pdf/conta-protegida/CG-Prestamista-v10 29042002 A 03042014.pdf">Vigência: 29/04/2002 até 03/04/2014</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </main>
        );
    }
}