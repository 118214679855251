import CryptoJS from 'crypto-js'
import {CRYPT_KEY, CRYPT_IV} from '../../constants'

/**
 * @param {any} decrypted 
 * @returns {string}
 */
export const encrypt = (decrypted) => {
    var message = "";

    switch(typeof decrypted){
        case 'object': message = JSON.stringify(decrypted); break;
        case 'string': message = decrypted; break;
        default: message = String(decrypted);
    }

    let keyHex = CryptoJS.enc.Utf8.parse(CRYPT_KEY);
    let ivHex = CryptoJS.enc.Utf8.parse(CRYPT_IV);
    let messageHex = CryptoJS.enc.Utf8.parse(message);
    let encrypted = CryptoJS.AES.encrypt(messageHex, keyHex, {
        iv: ivHex,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    
    return encrypted.toString(); // base64 result
    // return encrypted.ciphertext.toString(); // binary result
}

/**
 * @param {string} encrypted 
 * @returns {string}
 */
export const decrypt = (encrypted) => {
    let keyHex = CryptoJS.enc.Utf8.parse(CRYPT_KEY);
    let ivHex = CryptoJS.enc.Utf8.parse(CRYPT_IV);
    let decrypt = CryptoJS.AES.decrypt(encrypted, keyHex, {
        iv: ivHex,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return decrypt.toString(CryptoJS.enc.Utf8);
}