import React, { Component } from 'react'
import Menu from '../../components/Menu/External'
import Footer from '../../components/Footer'
import ModalSelectMethodPurchase from '../../components/Modal/SelectMethodPurchase'
import { PRODUCT_HOME_INSURANCE } from '../../constants'

export default class HomeInsurance extends Component {

    /**
     * Function Products List
     */
    productsList = () => {
        const products = PRODUCT_HOME_INSURANCE;
        return products.map((response, key) => {
            return <div className="plan-card" key={key}>
                <div className="header-card">
                    <p>Plano {response.plan}</p>
                </div>
                <div className="legend-plan no-item">
                    <p>Importância Segurada</p>
                </div>
                <div className="item-plan no-item">
                    <p className="title-item-plan">Incêndio, Raio, Explosão e Queda de Aeronaves</p>
                    <div className="englob-itens">
                        <p>{response.coverage_1}</p>
                    </div>
                </div>
                <div className="item-plan no-item">
                    <p className="title-item-plan">Roubo e Furto de Bens Mediante Arrombamento</p>
                    <div className="englob-itens">
                        <p>{response.coverage_2}</p>
                    </div>
                </div>
                <div className="item-plan no-item">
                    <p className="title-item-plan">Danos Elétricos</p>
                    <div className="englob-itens">
                        <p>{response.coverage_3}</p>
                    </div>
                </div>
                <div className="item-plan no-item">
                    <p className="title-item-plan">Fenômenos Naturais</p>
                    <div className="englob-itens">
                        <p>{response.coverage_4}</p>
                    </div>
                </div>
                <div className="item-plan no-item">
                    <p className="title-item-plan">Assistências Personalizadas</p>
                    <div className="englob-itens">
                    <p>{response.coverage_5}</p>
                    </div>
                </div>
                <div className="price-plan">
                    <p>{response.price}/mês</p>
                </div>
                <ModalSelectMethodPurchase 
                    productId={response.product_id}
                />
            </div>
        })
    }

    /**
     * Function Products Card
     */
    productsCard = () => {
        const products = PRODUCT_HOME_INSURANCE;

        return products.map((response, key) => {
            return <div className="card-tabela" key={key}>
                        <div className="title-card">
                            <p>Plano {response.plan}</p>
                        </div>

                        <div className="opt-card">
                            <small>Incêndio, Raio, Explosão e Queda de Aeronaves</small>
                            <p>{response.coverage_1}</p>
                            <br />
                            <small>Roubo e Furto de Bens Mediante Arrombamento</small>
                            <p>{response.coverage_2}</p>
                            <br />
                            <small>Danos Elétricos</small>
                            <p>{response.coverage_3}</p>
                            <br />
                            <small>Fenômenos Naturais</small>
                            <p>{response.coverage_4}</p>
                            <br />
                            <small>Assistências Personalizadas</small>
                            <p>{response.coverage_5}</p>
                        </div>

                        <div className="price-card">
                            <p>{response.price}/mês</p>
                        </div>

                        <ModalSelectMethodPurchase 
                            productId={response.product_id}
                        />
                    </div>
        })
    }

    /**
    * Render Component
    */
    render() {
        return (
            <main className="main">
                <section className="banner banner-residencial">
                    <div className="center-content interna-residencial">
                        <Menu activeMenu2={'active'} />
                        <div className="info-banner-interno">
                            <p className="_title-interno">Abra a porta da sua</p>
                            <p className="_title-interno">casa para a segurança</p>
                            <p className="_title-interno">E proteja sua família!</p>
                            <p style={{ maxWidth: 450, justifyContent: 'center', marginTop: 10, color: 'var(--secundary)', fontSize: '1.1rem', letterSpacing: '.04rem', lineHeight: '1.7rem' }}>
                                Você cliente Vivo Fixa agora pode contar com o Seguro MAPFRE Proteção Residencial Vivo protegendo o seu Lar contra os imprevistos do dia a dia.
                            </p>
                        </div>
                    </div>
                </section>
                <section className="seguros page-interna">
                    <div className="center-content reset-padding-bottom">
                        <div className="center-content-block">
                            <div className="bloc-interna">
                                <h2>Por que um Seguro Residencial?</h2>

                                <p>MAPFRE Proteção Residencial VIVO é o seguro que garante os danos causados a residência (casa ou apartamento) com coberturas de incêndio, queda de raio, explosão, danos elétricos, roubo e furto de bens.</p>

                                <p>Além disso, você pode escolher um plano que conta com cobertura de fenômenos naturais, como, por exemplo, estragos causados por tempestades, vendavais ou outros e assistência personalizada, com diversos serviços gratuitos.</p>

                                <div className="beneficios">
                                    <p className="title-beneficios">São mais de 10 benefícios</p>

                                    <ul className="lista-beneficios">
                                        <li>
                                            <img src="assets/images/icons/chaveiro.png" alt="Ícone Incêndio" />
                                            <p>Chaveiro</p>
                                        </li>
                                        <li>
                                            <img src="assets/images/icons/eletricista.png" alt="Ícone Incêndio" />
                                            <p>Eletricista</p>
                                        </li>
                                        <li>
                                            <img src="assets/images/icons/encanador.png" alt="Ícone Incêndio" />
                                            <p>Encanador</p>
                                        </li>
                                        <li>
                                            <img src="assets/images/icons/caixa-dagua.png" alt="Ícone Incêndio" />
                                            <p>Limpeza de Caixa D'água</p>
                                        </li>
                                        <li>
                                            <img src="assets/images/icons/calhas.png" alt="Ícone Incêndio" />
                                            <p>Limpeza de Calhas</p>
                                        </li>
                                        <li>
                                            <img src="assets/images/icons/caca-vazamentos.png" alt="Ícone Incêndio" />
                                            <p>Caça Vazamentos</p>
                                        </li>
                                        <li>
                                            <img src="assets/images/icons/revisao-eletrica.png" alt="Ícone Incêndio" />
                                            <p>Revisão na Instalação Elétrica</p>
                                        </li>
                                        <li>
                                            <img src="assets/images/icons/telhados.png" alt="Ícone Incêndio" />
                                            <p>Cobertura Provisória de Telhados</p>
                                        </li>
                                        <li>
                                            <img src="assets/images/icons/sub-telhas.png" alt="Ícone Incêndio" />
                                            <p>Substituição de Telhas</p>
                                        </li>
                                        <li>
                                            <img src="assets/images/icons/lubrificacao.png" alt="Ícone Incêndio" />
                                            <p>Lubrificação de Dobradiças e Fechaduras</p>
                                        </li>
                                        <li>
                                            <img src="assets/images/icons/vigilancia.png" alt="Ícone Incêndio" />
                                            <p>Segurança e vigilância</p>
                                        </li>
                                        <li>
                                            <img src="assets/images/icons/profissionais.png" alt="Ícone Incêndio" />
                                            <p>Indicação de profissionais</p>
                                        </li>                                       
                                    </ul>

                                    <a href="#tabela-planos" className="bt a-transition">
                                        Conheça todos os planos
                                        <span className="a-transition"><i className="fas fa-arrow-right" /></span>
                                    </a>
                                </div>
                            </div>

                            <div className="spacer">
                                <span className="block" />
                                <hr />
                            </div>

                            <div className="bloc-interna">
                                <h2>Como funciona?</h2>

                                <ul className="list-options-interna">
                                    <li>1. Escolha um dos planos;</li>
                                    <li>2. Preencha seus dados;</li>
                                    <li>3. Informe o número do telefone da Linha Fixa;</li>
                                    <li>4. Pague as mensalidades através da conta de telefone da fixa;</li>
                                    <li>5. Receba seu Certificado de Seguro.</li>
                                </ul>
                            </div>

                            <div className="bloc-interna">
                                <h2>Vantagens</h2>

                                <p>Com o seu Seguro MAPFRE Proteção Residencial Vivo, sua casa fica protegida contra os imprevistos do dia a dia e você ainda pode contar com um pacote de Assistências 24 horas por dia, 7 dias por semana, planos e mensalidades de acordo com o seu perfil!</p>

                                <p>E o melhor, o pagamento do seguro é cobrado direto na sua conta de telefone da fixa mensalmente proporcionando mais facilidades.</p>
                            </div>

                        </div>
                    </div>
                    <div className="center-tabela">
                        <div className="tabela-planos" id="tabela-planos">
                            <div className="plan-card legendas">
                                <div className="header-card" style={{ opacity: "0" }}>
                                    <p>.</p>
                                </div>

                                <div className="legend-plan" style={{ justifyContent: "center", textTransform: "uppercase" }}>
                                    <p>Coberturas</p>
                                </div>

                                <div className="item-plan" style={{ justifyContent: "center", textAlign: "center" }}>
                                    <p>Incêndio, Raio, Explosão e Queda de Aeronaves</p>
                                </div>

                                <div className="item-plan" style={{ justifyContent: "center", textAlign: "center" }}>
                                    <p>Roubo e Furto de Bens Mediante Arrombamento</p>
                                </div>

                                <div className="item-plan" style={{ justifyContent: "center", textAlign: "center" }}>
                                    <p>Danos Elétricos</p>
                                </div>

                                <div className="item-plan" style={{ justifyContent: "center", textAlign: "center" }}>
                                    <p>Fenômenos Naturais</p>
                                </div>

                                <div className="item-plan" style={{ justifyContent: "center", textAlign: "center" }}>
                                    <p>Assistências Personalizadas</p>
                                </div>

                                <div className="item-plan no-item" style={{ opacity: "0" }}>
                                    <p>.</p>
                                </div>

                                <div className="price-plan" style={{ opacity: "0" }}>
                                    <p>.</p>
                                </div>

                                <a href="/" className="bt-plan" style={{ opacity: "0" }}>
                                    <p>.</p>
                                </a>
                            </div>
                            {this.productsList()}
                        </div>
                        

                        <div className="cards-tabela">
                            {this.productsCard()}
                        </div>


                        <div className="txt-info-table center-content-block">
                            <p><strong>FRANQUIAS:</strong></p>

                            <p><strong>Incêndio/Raio/Explosão e Queda de Aeronave:</strong> 10% dos prejuíxos, com mínimo de R$ 150,00 em caso de Queda de Raio</p>
                            <p><strong>Dano Elétrico:</strong> 10% dos prejuízos, com mínimo de R$ 150,00</p>
                        </div>
                    </div>

                    <div className="center-content reset-padding-top">
                        <div className="center-content-block">
                            <div className="bloc-interna">
                                <h2>Dúvidas</h2>

                                <details open>
                                    <summary>Quem pode ser segurado?</summary>
                                    <div className="content-details">
                                        <p>É a pessoa física cliente da Vivo que optar pelo seguro oferecido pela seguradora e que tenha aderido à apólice (contrato) do seguro mediante pagamento das parcelas (prêmio) e que possua no mínimo 18 (dezoito) anos de idade.</p>
                                    </div>
                                </details>

                                <details>
                                    <summary>Quem é o Estipulante?</summary>
                                    <div className="content-details">
                                        <p>Pessoa jurídica que propõe a contratação de plano coletivo com poderes para a representação do segurado nos termos da legislação e regulação em vigor, sendo identificada como estipulante/instituidor, quando participar total ou parcialmente do custeio do plano e como estipulante/averbador, quando não participar do custeio. "Neste caso o Estipulante do seguro é a Vivo que, em parceria com a MAPFRE, é responsável pelo oferecimento. O serviço da Seguradora será cobrado em fatura da Vivo/Estipulante."</p>
                                    </div>
                                </details>

                                <details>
                                    <summary>O que é prêmio do seguro?</summary>
                                    <div className="content-details">
                                        <p>É o valor pago pelo Segurado para que a Seguradora possa garantir a cobertura da apólice.</p>
                                    </div>
                                </details>

                                <details>
                                    <summary>Quem é o corretor de seguros?</summary>
                                    <div className="content-details">
                                        <p>A corretora de seguros responsável pelo produto MAPFRE PROTEÇÃO RESIDENCIAL VIVO é a Telefônica Corretora de Seguros Ltda.</p>
                                    </div>
                                </details>

                                <details>
                                    <summary>Qual é a regra de cancelamento automático do seguro? </summary>
                                    <div className="content-details">
                                        <p>Este seguro ficará automaticamente cancelado, sem qualquer restituição das parcelas pagas e emolumentos, quando:</p>

                                        <p>- Decorrer o prazo para pagamento do valor de qualquer uma das parcelas, na data indicada na Apólice ou no documento de cobrança, sem que tenha sido efetuado; e</p>

                                        <p>- Houver fraude ou tentativa de fraude.</p>
                                    </div>
                                </details>

                                <details>
                                    <summary>Quando inicia a cobertura do seguro?</summary>
                                    <div className="content-details">
                                        <p>O início da cobertura do seguro se dará a partir das 24 horas do pagamento da primeira parcela do seguro.</p>
                                    </div>
                                </details>

                                <details>
                                    <summary>Como será realizada a cobrança do seguro?</summary>
                                    <div className="content-details">
                                        <p>O seguro será cobrado mensalmente na Fatura Telefônica.</p>
                                    </div>
                                </details>

                                <details>
                                    <summary>Como cancelo o meu seguro?</summary>
                                    <div className="content-details">
                                        <p>Há duas formas de cancelar o Seguro:</p>

                                        <p>1) Neste site, através dá área MINHA CONTA, basta utilizar seu login e senha, caso ainda não tenha, você pode se cadastrar ou;</p>

                                        <p>2) Através da Central de Relacionamento no telefone 4002-7041(capital e regiões metropolitanas) e 0800 570 7041(demais localidades) ou pelo SAC 0800 570 7045. Deficientes Auditivos e de Fala 0800 775 5045.</p>
                                    </div>
                                </details>

                                <details>
                                    <summary>Quem garante as coberturas do meu seguro?</summary>
                                    <div className="content-details">
                                        <p>MAPFRE SEGUROS GERAIS S.A., inscrita no CNPJ 61.074.175/0001-38, de acordo com a Nota Técnica e respectivas Condições Gerais aprovadas pela Superintendência de Seguros Privados – SUSEP, conforme Processo nº. 15414.100431/2002-51.</p>

                                        <p>MAPFRE CAPITALIZAÇÃO S.A., inscrita no CNPJ 09.382.998/0001-00, de acordo com a Nota Técnica e respectivas Condições Gerais aprovadas pela Superintendência de Seguros Privados - SUSEP conforme Processo nº. 15414.000959/2008-16.</p>
                                    </div>
                                </details>

                            </div>

                            <div className="bloc-interna" id="cond-geral">
                                <h2>Condições Gerais</h2>

                                <ul className="list-options-interna">
                                    <li><a href="assets/pdf/seguro-residencial/Manual do Segurado Residencial.pdf">Manual do Segurado</a></li>
                                    <li><a href="assets/pdf/seguro-residencial/Regulamento Assistência.pdf">Regulamento Serviços de Assistência</a></li>
                                    <li><a href="assets/pdf/seguro-residencial/CG_Residencial_-_V.2.7 01032018 a atual.pdf">Vigência: 01/03/2018 – em vigor atualmente</a></li>
                                    <li><a href="assets/pdf/seguro-residencial/CG_residencial-v24 25092014 a25112014.pdf">Vigência: 26/11/2014 até 28/02/2018</a></li>
                                    <li><a href="assets/pdf/seguro-residencial/CG_Residencial-v25 26112014 A 23122014.pdf">Vigência: 26/11/2014 até 23/12/2014</a></li>
                                    <li><a href="assets/pdf/seguro-residencial/CG_residencial-v26 26112014 A 28022018.pdf">Vigência: 25/09/2014 até 25/11/2014  </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </main>
        );
    }
}