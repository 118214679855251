import React from 'react'

export default (props) => {
    return props.rows.map((response, key) => (
        <div className="card" key={key}>
            <p style={{ textTransform: 'uppercase' }}>
                <strong>{response.productName}</strong>
            </p>
            <p style={{ textTransform: 'uppercase' }}>{response.description}</p>
            <p>Cancelamento em: <strong>{response.canceledNotificationDate}</strong></p>
            <p>Motivo do cancelamento: <strong>Cliente cancelou pelo site</strong></p>
            <p>Protocolo: <strong>20203121</strong></p>
            <span className="status" style={{ textTransform: 'uppercase' }}>Status: Cancelado</span>
        </div>
    ))
};