import React from 'react'
import PasswordMask from 'react-password-mask'
import ErrorMessage from '../../../Errors'
import ModalRecoverPassword from '../../../Modal/RecoverPassword'

export default (props) => {
    return (
        <>
            <form onSubmit={props.handleSubmit} className="frm-login">
                <input
                    type="email"
                    name="email"
                    value={props.email}
                    onChange={props.handleChange}
                    placeholder="E-MAIL"
                    required
                />
                <PasswordMask
                    name="password"
                    id="password"
                    placeholder="SENHA"
                    value={props.password}
                    onChange={props.handleChange}
                    showButtonContent={'Exibir'}
                    hideButtonContent={'Ocultar'}
                />               
                <ErrorMessage display={props.displayErrorMessage} onCloseErrorMessage={(e) => props.onCloseErrorMessage(e)} message={props.errorMessage} />

                <div className="termscheck" style={{ display: 'grid', gridTemplateColumns: '48px 1fr', alignItems: 'center', margin: '20px 0 0 0' }}>
                    <label htmlFor="toggle" style={{ position: 'relative' }}>
                        <input type="checkbox" id="toggle" name="checked" required />
                        <span className='toogl'></span>
                    </label>

                    <div style={{ whiteSpace: 'normal', textAlign: 'left', fontSize: '14px', color: 'white' }}>
                        <p style={{ marginLeft: '10px', marginBottom: '0', color: 'hsla(0, 0%, 40%, 1)' }}>Li e concordo com os Termos de Uso e Políticas de Privacidade</p>
                    </div>
                </div>

                <button type="submit" anime="bg-transition" bt="filled">Entrar</button>
            </form>
            <ModalRecoverPassword />
        </>
    );
}