import React from 'react'

export default (props) => {
    return (
        <div style={{ display: `${(props.display) ? 'block' : 'none'}` }}>
            <div className="content">
                <p>Você está a um passo de adquirir segurança e tranquilidade para você e sua família!</p>
            </div>
            <div className="footer">
                {/* <button bt="leaked" data-link={`login?name=${props.name}&plan=${props.plan}&price=${props.price}`}>Já sou cliente</button> */}
                <button bt="filled" data-link={`login?subscription=true&productId=${props.productId}`} anime="bg-transition">Criar cadastro</button>
            </div>
        </div>
    );
}