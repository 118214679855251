import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

import {
    URL_BACKEND,
    REQUESTER_NAME
} from '../../constants'

const HEADERS = {
    'Content-Type': 'application/json',
    'X-FS-Correlation-ID': uuidv4(),
    'X-FS-Requester-Name': REQUESTER_NAME
}
   
/**
 * Function Abr FS
 * @param {int} msisdn 
 */
export async function checkPhone(msisdn) {
    try {
        const response = await axios.get(`${URL_BACKEND}/bff/api/v1/abr/search/${msisdn}`, { headers: HEADERS });
        return { status: true, custom_token: response.data };
    } catch (error) {
        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
        }
        
        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
        }

        return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };        
    }
}