import React from 'react'
import NumberFormat from 'react-number-format'
import ErrorMessage from '../../../../Errors'

export default (props) => {
    return (
        <section className="stage" style={{ display: `${(props.display) ? 'block' : 'none'}` }}>
            <header>
                <h1 style={{ textTransform: 'uppercase' }}>Falta pouco para aderir o seu seguro!</h1>
                <h2 style={{ textTransform: 'uppercase' }}>Precisamos validar seu número VIVO Fixo</h2>
            </header>
            <div className="bloc-stage">
                <div className="msg-comp">
                    <p>Digite o número <strong>VIVO Fixo Residencial</strong>.</p>
                    <p>Você irá receber uma ligação automática com um código de acesso.</p>
                </div>
                <form className="new-pass" onSubmit={props.handleSubmit}>
                    <fieldset>
                        <legend>Digite seu número VIVO Fixo Residencial</legend>
                        <NumberFormat
                            className="input-send-pincode"
                            value={props.phone}
                            onChange={props.handleChange}
                            type="text"
                            name="phone"
                            placeholder="Número de telefone VIVO"
                            required
                            format="(##) #####-####" mask="_"
                            autoFocus={true}
                        />
                    </fieldset>
                    <ErrorMessage 
                        display={props.displayErrorMessage} 
                        onCloseErrorMessage={(e) => props.onCloseErrorMessage(e)} 
                        message={props.errorMessage} 
                    />
                    <button type="submit" anime="bg-transition" bt="filled">Continuar</button>
                </form>
            </div>
        </section>
    );
}