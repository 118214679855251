import React from 'react'

export default (props) => {
    return (
        <section className="stage" style={{ display: `${(props.display) ? 'block' : 'none'}` }}>
            <header>
                <div className="msg-congrats" style={{ marginBottom: 0 }}>
                    <p className="uppercase"><strong>Conta verificada com sucesso</strong></p>
                </div>
                <section className="card-plan">
                    <p style={{ textTransform: 'uppercase' }}>
                        <strong>{props.name}</strong>
                    </p>
                    <p style={{ textTransform: 'uppercase' }}>Plano Completo</p>
                    <p>Vigência: 1 ano após o pagamento</p>
                    {/* <p>
                        <a href="/#">Incluir beneficiários</a>
                    </p> */}
                    <p>
                        <strong>{props.price}</strong>
                    </p>
                </section>
                <p>Você será cobrado mensalmente em sua conta de telefone fixo e precisará estar em dia com os pagamentos para contar com todos os beneficios do seu seguro.</p>
                <form className="new-pass" onSubmit={props.handleSubmit}>
                    <div className="acept-terms">
                        <div className="opt">
                            <input
                                checked={props.condition}
                                onChange={props.handleChange}
                                type="checkbox"
                                name="condition"
                                required
                            /> <p>Concordo que li as <a href="/seguro-residencial#cond-geral" target="_blank">Condições gerais do Produto</a></p>
                        </div>
                        <div className="opt">
                            <input
                                checked={props.debit}
                                onChange={props.handleChange}
                                type="checkbox"
                                name="debit"
                                required
                            /> <p>Concordo em debitar o valor na minha conta de telefone</p>
                        </div>
                    </div>
                    <button bt="filled" className="_ychecked" type="submit" >Concluir compra</button>
                </form>
            </header>
        </section>
    );
}