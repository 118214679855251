import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import Home from './pages/Home'
import HomeInsurance from './pages/HomeInsurance'
import ProtectedAccount from './pages/ProtectedAccount'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'

/**
 * Authorize
 */
import { isAuthorizated } from './utils';

const PrivateRoute = ({ component: Component, ...rest }) => (   
    <Route
        {...rest}
        render={props =>
            isAuthorizated() === true ? (
                <Component {...props} />
            ) : (
                    <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                )
        }
    />
);

export default () => (
    <BrowserRouter basename="/">
        <Switch>
            <Route path="/" exact={true} component={Home} />
            <Route path="/seguro-residencial" exact={true} component={HomeInsurance} />
            <Route path="/conta-protegida" exact={true} component={ProtectedAccount} />
            <Route path="/login" exact={true} component={Login} />
            <PrivateRoute path="/dashboard" exact={true} component={Dashboard} />
            <Route path="*" component={() => <Redirect to="/" />} />
        </Switch>
    </ BrowserRouter>
);