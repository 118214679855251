import React, { Component } from 'react'

import {
    KEY_GOOGLE_RECAPTCHA
} from '../../../../constants'

import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3'

import {
    validatorEmail
} from '../../../../utils'

import {
    sendEmailSupport
} from '../../../../services'

import ErrorMessage from '../../../Errors'
import Loading from '../../../Loading'

export default class FormFooterSendEmailSupport extends Component {

    constructor() {
        super();

        this.state = {
            name: '',
            email: '',
            subject: '',
            message: '',
            loading: false,
            displayErrorMessage: false,
            errorMessage: null,
            displaySuccessSendEmail: false
        }

        this.baseState = this.state;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    /**
    * On Close Error Message
    */
    onCloseErrorMessage = (e) => {
        this.setState({
            displayErrorMessage: false,
            errorMessage: null
        });
    }

    /**
    * Handle Change Form Send Email and Password
    * @param {event} e 
    */
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    /**
    * Handle Submit Form Send Email and Password
    * @param {event} e 
    */
    async handleSubmit(e) {
        e.preventDefault();

        const {
            name,
            email,
            subject,
            message,
            token
        } = this.state;

        const validator = validatorEmail(email);

        if (!validator.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: validator.message
            });
            return;
        }

        this.setState({
            loading: true,
            displayErrorMessage: false,
            errorMessage: null
        });

        const response = await sendEmailSupport({
            name: name,
            email: email,
            subject: subject,
            message: message
        }, token);

        if (response.code) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: response.message
            });
            return;
        }

        if (!response.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: response.message
            });
            return;
        }

        this.setState({
            loading: false,
            displaySuccessSendEmail: true
        });
    }


    render() {

        const {
            name,
            email,
            subject,
            message,
            loading,
            displayErrorMessage,
            errorMessage,
            displaySuccessSendEmail
        } = this.state;

        return (
            <>
                <Loading display={loading} />
                <form onSubmit={this.handleSubmit}>
                    <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={this.handleChange}
                        placeholder="Seu nome"
                        required
                    />
                    <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={this.handleChange}
                        placeholder="Seu e-mail"
                        required
                    />
                    <input
                        type="text"
                        name="subject"
                        value={subject}
                        onChange={this.handleChange}
                        placeholder="Assunto"
                        required
                    />
                    <textarea
                        name="message"
                        value={message}
                        onChange={this.handleChange}
                        placeholder="Mensagem"
                        required
                    />
                    <ErrorMessage
                        display={displayErrorMessage}
                        onCloseErrorMessage={(e) => this.onCloseErrorMessage(e)}
                        message={errorMessage}
                    />

                    <div style={{
                        display: `${(displaySuccessSendEmail) ? 'block' : 'none'}`,
                        backgroundColor: '#d4edda',
                        color: '#155724',
                        marginTop: '1rem',
                        border: 'none',
                        fontSize: 14,
                        borderRadius: '0.1rem',
                        padding: '1.2rem 0.8rem',
                        width: '100%'
                    }}>
                        <p>Enviamos seu email, em breve entraremos em contato!</p>
                    </div>
                    
                    <GoogleReCaptchaProvider reCaptchaKey={KEY_GOOGLE_RECAPTCHA}>
                        <GoogleReCaptcha onVerify={token => this.setState({ token: token })} />
                    </GoogleReCaptchaProvider>

                    <button type="submit" className="bt a-transition">Enviar<span className="a-transition"><i className="fas fa-arrow-right" /></span>
                    </button>
                </form>
            </>
        );
    }
}