import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

import {
    URL_BACKEND,
    BRAND_NAME,
    REQUESTER_NAME,
    CLIENT_ID,
    CLIENT_SECRET
} from '../../constants'

const HEADERS = {
    'Content-Type': 'application/json',
    'X-FS-Correlation-ID': uuidv4(),
    'X-FS-Requester-Name': REQUESTER_NAME,
    'X-FS-Brand-Name': BRAND_NAME,
    'client_id': CLIENT_ID,
    'client_secret': CLIENT_SECRET,
    'X-FS-Is-BFF': 'true',
    'X-Frame-Options': 'SAMEORIGIN',
    'X-XSS-Protection': '1',
    'Expect-CT': 'max-age=86400, enforce, report-uri="https://fs-portal-seguros.web.app/report"',
    'Access-Control-Allow-Origin': '*',
    'Strict-Transport-Security': 'max-age=31536000; includeSubDomains'
}
   
/**
 * Function Authorize FS Auth Token
 * @param {json} body 
 */
export async function authorization(body) {
    try {
        const response = await axios.post(`${URL_BACKEND}/authorization/api/v1/authorize/insurance?extref_fixo=true`, body, { headers: HEADERS });
        return { status: true, custom_token: response.data.authorization.custom_token };
    } catch (error) {
        if (error.response) {
            return { status: false, message: `Ops, você não tem esse produto cadastrado. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
        }
        
        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
        }

        return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };        
    }
}