import React from 'react'
import BtnLogount from '../../Buttons/Logout'

export default () => {
    return (
        <header>
            <div className="center">
                <div className="logo">
                    <img src="assets/images/logo/logo-vivo.png" alt="Logo VIVO" />
                    <img src="assets/images/logo/logo-mapfre.svg" alt="Logo MAPFRE" />
                </div>
                <div className="menu">
                    <a href="/dashboard">Meus Produtos</a>
                    <div className="user">
                        <p><strong>---</strong></p>
                        <div className="options">
                            <BtnLogount />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}