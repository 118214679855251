import React, { Component } from 'react'
import queryString from 'query-string'
import ModalTemplate from '../Template'
import FormSendPurchasePlan from '../../Forms/Modal/NewSubscriptionProducts/SendPurchasePlan'
import FormSuccessPurchasePlan from '../../Forms/Modal/NewSubscriptionProducts/SuccessPurchasePlan'
import Loading from '../../Loading'

import ErrorMessage from '../../Errors'

import {
    getSaleValidate,
    sendSaleValidate
} from '../../../services'

export default class ModalNewSubscriptionProducts extends Component {

    /**
     * Constructor
     */
    constructor() {
        super();

        this.state = {
            loading: false,
            errorMessage: null,
            displayHeader: true,
            displayErrorMessage: false,
            displayModal: false,
            displayFormSendPurchasePlan: false,
            displayFormSuccessPurchasePlan: false,
            condition: false,
            debit: false,
            productName: '',
            price: '',
            extref: ''
        }

        this.baseState = this.state;
        this.onCloseErrorMessage = this.onCloseErrorMessage.bind(this);
        this.handleChangeFormSendPurchasePlan = this.handleChangeFormSendPurchasePlan.bind(this);
        this.handleSubmitFormSendPurchasePlan = this.handleSubmitFormSendPurchasePlan.bind(this);
    }

    /**
     * Component Did Mount
     */
    componentDidMount() {
        const params = queryString.parse(window.location.search);
        if (params.saleVerify) {
            this.getSaleValidate(params.saleVerify);
        }
    }

    /**
     * On Click Modal Display
     */
    onClickModalDisplay = (status, e) => {
        e.preventDefault();
        if (status) {
            this.setState({ displayModal: true });
            return;
        }

        this.setState(this.baseState);
        return;
    }

    /**
     * On Close Error Message
     */
    onCloseErrorMessage = () => {
        this.setState({
            displayErrorMessage: false,
            errorMessage: null
        });
    }

    /**
     * Function Get Sale Validate
     * @param {string} extref 
     */
    async getSaleValidate(extref) {

        this.setState({
            loading: true
        });

        let response = await getSaleValidate(extref);

        if (!response.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: response.message,
                displayModal: true,
                displayFormSendPurchasePlan: false,
            });
            return;
        }

        this.setState({
            loading: false,
            extref: extref,
            productName: response.productName,
            price: response.price,
            displayModal: true,
            displayFormSendPurchasePlan: true,
            displayFormSuccessPurchasePlan: false
        });
    }

    /**
     * Handle Change Form Purchase Plan
     * @param {event} e 
     */
    handleChangeFormSendPurchasePlan(e) {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({ [e.target.name]: value });
    }

    /**
     * Handle Submit Form Send Purchase Plan
     * @param {event} e 
     */
    async handleSubmitFormSendPurchasePlan(e) {
        e.preventDefault();

        const {
            extref
        } = this.state;

        this.setState({
            loading: true,
            displayErrorMessage: false,
            errorMessage: null
        });

        let response = await sendSaleValidate({
            hash: extref
        });

        if (!response.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: response.message
            });
            return;
        }

        this.setState({
            loading: false,
            displayFormSendPurchasePlan: false,
            displayFormSuccessPurchasePlan: true
        });
    }

    /**
     * Render Component
     */
    render() {
        const {
            loading,
            errorMessage,
            displayErrorMessage,
            displayModal,
            displayFormSendPurchasePlan,
            displayFormSuccessPurchasePlan,
            productName,
            price,
            condition,
            debit
        } = this.state;

        return (
            <>
                {/* <button bt="filled" style={{ width: 'auto' }} onClick={(e) => this.onClickModalDisplay(true, e)}>Adquirir novo seguro</button> */}

                <ModalTemplate
                    display={displayModal}
                    headerTitle={'Adquirir novo seguro'}
                    onClickModalDisplay={this.onClickModalDisplay}
                >
                    <Loading display={loading} />

                    <FormSendPurchasePlan
                        name={productName}
                        price={price}
                        condition={condition}
                        debit={debit}
                        errorMessage={errorMessage}
                        display={displayFormSendPurchasePlan}
                        displayErrorMessage={displayErrorMessage}
                        handleChange={this.handleChangeFormSendPurchasePlan}
                        handleSubmit={this.handleSubmitFormSendPurchasePlan}
                        onCloseErrorMessage={this.onCloseErrorMessage}
                    />

                    <ErrorMessage
                        display={displayErrorMessage}
                        onCloseErrorMessage={this.onCloseErrorMessage}
                        message={errorMessage}
                    />

                    <FormSuccessPurchasePlan
                        productName={productName}
                        display={displayFormSuccessPurchasePlan}
                        onClickModalDisplay={this.onClickModalDisplay}
                    />

                </ModalTemplate>
            </>
        )
    }
}