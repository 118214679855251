import React, { Component } from 'react'
import {
    validatorEmail
} from '../../../utils'

import {
    sendRequestForgotPassword
} from '../../../services'

import ModalTemplate from '../Template'
import FormSendEmail from '../../Forms/Modal/RecoverPassword/SendEmail'
import FormSuccessEmail from '../../Forms/Modal/RecoverPassword/SuccessEmail'
import Loading from '../../Loading'

export default class ModalRecoverPassword extends Component {

    /**
     * Constructor
     */
    constructor() {
        super();

        this.state = {
            loading: false,
            errorMessage: null,
            displayHeader: true,
            displayErrorMessage: false,
            displayModal: false,
            displayFormSendEmail: true,
            displayFormValidateEmail: false,
            displayFormSubscriptionNotFound: false,
            email: ''
        }

        this.baseState = this.state;
        this.onCloseErrorMessage = this.onCloseErrorMessage.bind(this);
        this.handleChangeFormSendEmail = this.handleChangeFormSendEmail.bind(this);
        this.handleSubmitFormSendEmail = this.handleSubmitFormSendEmail.bind(this);
    }

    /**
     * On Click Modal Display
     */
    onClickModalDisplay = (status, e) => {
        e.preventDefault();
        if (status) {
            this.setState({ displayModal: true });
            return;
        }

        this.setState(this.baseState);
        return;
    }

    /**
     * On Close Error Message
     */
    onCloseErrorMessage = (e) => {
        this.setState({
            displayErrorMessage: false,
            errorMessage: null
        });
    }

    /**
     * Handle Change Form Send Email
     * @param {event} e 
     */
    handleChangeFormSendEmail(e) {
        this.setState({ email: e.target.value });
    }

    /**
     * Handle Submit Form Send Email
     * @param {event} e 
     */
    async handleSubmitFormSendEmail(e) {
        e.preventDefault();

        const { email } = this.state;

        const validator = validatorEmail(email);

        if (!validator.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: validator.message
            });
            return;
        }

        this.setState({
            loading: true,
            displayErrorMessage: false,
            errorMessage: null
        });

        const response = await sendRequestForgotPassword({
            email: validator.email
        });

        if (!response.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: response.message
            });
            return;
        }

        this.setState({
            loading: false,
            email: validator.email,
            displayFormSendEmail: false,
            displayFormValidateEmail: true
        });
    }

    /**
     * Render Component
     */
    render() {
        const {
            loading,
            errorMessage,
            displayErrorMessage,
            displayModal,
            displayFormSendEmail,
            displayFormValidateEmail,
            email
        } = this.state;

        return (
            <>
                <p className="rec-pass" onClick={(e) => this.onClickModalDisplay(true, e)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>Recuperar senha</p>
                <ModalTemplate
                    display={displayModal}
                    headerTitle={'Recuperação de senha'}
                    onClickModalDisplay={this.onClickModalDisplay}
                >
                    <Loading display={loading}/>
                    
                    <FormSendEmail
                        email={email}
                        display={displayFormSendEmail}
                        errorMessage={errorMessage}
                        displayErrorMessage={displayErrorMessage}
                        handleChange={this.handleChangeFormSendEmail}
                        handleSubmit={this.handleSubmitFormSendEmail}
                        onCloseErrorMessage={this.onCloseErrorMessage}                        
                    />
                    
                    <FormSuccessEmail 
                        email={email}
                        display={displayFormValidateEmail}
                        errorMessage={errorMessage}
                        displayErrorMessage={displayErrorMessage}
                        handleChange={this.handleChangeFormValidateEmail}
                        handleSubmit={this.handleSubmitFormValidateEmail}
                        onCloseErrorMessage={this.onCloseErrorMessage}
                    />
                </ModalTemplate>
            </>
        )
    }
}