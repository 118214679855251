import React from 'react'
import PasswordMask from 'react-password-mask'
import ErrorMessage from '../../../../Errors'

export default (props) => {
    return (
        <section className="stage" style={{ display: `${(props.display) ? 'block' : 'none'}` }}>
            <header>
                <h1 style={{ textTransform: 'uppercase' }}>Falta pouco para aderir o seu seguro!</h1>
                <h2 style={{ textTransform: 'uppercase' }}>Preencha seus dados cadastrais para prosseguir com a compra</h2>
            </header>
            <div className="bloc-stage">
                <div className="breadcrumb">
                    <p>1. Dados pessoais</p>
                    <p>2. Localização</p>
                    <p className="active-breadc">3. Dados de Login</p>
                </div>
                <form className="personal-data" onSubmit={props.handleSubmit}>
                    <fieldset>
                        <legend>E-mail(será seu e-mail para login)</legend>
                        <input
                            value={props.email}
                            onChange={props.handleChange}
                            type="email"
                            name="email"
                            placeholder="Digite um e-mail válido"
                            autoFocus={true}
                            required
                        />
                    </fieldset>
                    <fieldset>
                        <legend>Confirmar e-mail</legend>
                        <input
                            value={props.confirmEmail}
                            onChange={props.handleChange}
                            type="email"
                            name="confirmEmail"
                            placeholder="Digite seu e-mail mais uma vez"
                            required
                        />
                    </fieldset>
                    <fieldset>
                        <legend>Escolha uma senha</legend>
                        <PasswordMask
                            name="password"
                            id="password"
                            placeholder="Escolha uma senha segura"
                            value={props.password}
                            onChange={props.handleChange}
                            showButtonContent={'Exibir'}
                            hideButtonContent={'Ocultar'}
                        />
                    </fieldset>
                    <ErrorMessage 
                        display={props.displayErrorMessage} 
                        onCloseErrorMessage={(e) => props.onCloseErrorMessage(e)} 
                        message={props.errorMessage} 
                    />
                    <button type="submit" anime="bg-transition" bt="filled">Continuar</button>
                    <small style={{ textTransform: 'uppercase', cursor: 'pointer', textDecoration: 'underline' }} onClick={(e) => props.onClickReturnFormSendRegistrationLocation(e)}>Voltar</small>
                </form>
            </div>
        </section>
    );
}