import React from 'react'
import NumberFormat from 'react-number-format'
import ErrorMessage from '../../../../Errors'

export default (props) => {
    return (
        <section className="stage" style={{ display: `${(props.display) ? 'block' : 'none'}` }}>
            <header>
                <h1 style={{ textTransform: 'uppercase' }}>Falta pouco para aderir o seu seguro!</h1>
                <h2 style={{ textTransform: 'uppercase' }}>Preencha seus dados cadastrais para prosseguir com a compra</h2>
            </header>
            <div className="bloc-stage">
                <div className="breadcrumb">
                    <p>1. Dados pessoais</p>
                    <p className="active-breadc">2. Localização</p>
                    <p>3. Dados de Login</p>
                </div>
                <form className="personal-data" onSubmit={props.handleSubmit}>
                    <fieldset>
                        <legend>CEP</legend>
                        <NumberFormat
                            value={props.cep}
                            onChange={props.handleChange}
                            type="text"
                            name="cep"
                            placeholder="Digite seu CEP"
                            format="#####-###" mask="_"
                            onBlur={() => props.onBlurSearchCep(props.cep)}
                            required
                        />
                    </fieldset>
                    <div className="duo">
                        <fieldset>
                            <legend>Endereço</legend>
                            <input
                                value={props.address}
                                onChange={props.handleChange}
                                type="text"
                                name="address"
                                placeholder="Digite seu endereço"
                                required
                            />
                        </fieldset>
                        <fieldset>
                            <legend>Número</legend>
                            <input
                                value={props.addressNumber}
                                onChange={props.handleChange}
                                type="text"
                                name="addressNumber"
                                placeholder="Digite seu número"
                                required
                            />
                        </fieldset>
                    </div>
                    <div className="duo">
                        <fieldset>
                            <legend>Bairro</legend>
                            <input
                                value={props.neighborhood}
                                onChange={props.handleChange}
                                type="text"
                                name="neighborhood"
                                placeholder="Digite seu bairro"
                                required
                            />
                        </fieldset>
                        <fieldset>
                            <legend>Cidade</legend>
                            <input
                                value={props.city}
                                onChange={props.handleChange}
                                type="text"
                                name="city"
                                placeholder="Digite seu cidade"
                                required
                            />
                        </fieldset>
                    </div>
                    <fieldset>
                        <legend>Estado</legend>
                            <select
                                name="state"
                                value={props.state}
                                onChange={props.handleChange}
                                required
                            >
                            <option value="">[Selecione um estado]</option>
                            <option value="AC">Acre</option>
                            <option value="AL">Alagoas</option>
                            <option value="AP">Amapá</option>
                            <option value="AM">Amazonas</option>
                            <option value="BA">Bahia</option>
                            <option value="CE">Ceará</option>
                            <option value="DF">Distrito Federal</option>
                            <option value="ES">Espírito Santo</option>
                            <option value="GO">Goiás</option>
                            <option value="MA">Maranhão</option>
                            <option value="MT">Mato Grosso</option>
                            <option value="MS">Mato Grosso do Sul</option>
                            <option value="MG">Minas Gerais</option>
                            <option value="PA">Pará</option>
                            <option value="PB">Paraíba</option>
                            <option value="PR">Paraná</option>
                            <option value="PE">Pernambuco</option>
                            <option value="PI">Piauí</option>
                            <option value="RJ">Rio de Janeiro</option>
                            <option value="RN">Rio Grande do Norte</option>
                            <option value="RS">Rio Grande do Sul</option>
                            <option value="RO">Rondônia</option>
                            <option value="RR">Roraima</option>
                            <option value="SC">Santa Catarina</option>
                            <option value="SP">São Paulo</option>
                            <option value="SE">Sergipe</option>
                            <option value="TO">Tocantins</option>
                        </select>                        
                    </fieldset>
                    <fieldset>
                        <legend>Complemento</legend>
                        <input
                            value={props.addressComplement}
                            onChange={props.handleChange}
                            type="text"
                            name="addressComplement"
                            placeholder="Bloco, APTO, Casa..."
                            required
                        />
                    </fieldset>
                    <ErrorMessage
                        display={props.displayErrorMessage}
                        onCloseErrorMessage={(e) => props.onCloseErrorMessage(e)}
                        message={props.errorMessage}
                    />
                    <button type="submit" anime="bg-transition" bt="filled">Continuar</button>
                    <small style={{ textTransform: 'uppercase', cursor: 'pointer', textDecoration: 'underline' }} onClick={(e) => props.onClickReturnFormSendRegistrationData(e)}>Voltar</small>
                </form>
            </div>
        </section>

    );
}