import React from 'react'

export default (props) => {
    return (
        <section className="stage" style={{ display: `${(props.display) ? 'block' : 'none'}` }}>
            <header>
                <div className="msg-congrats">
                    <p className="uppercase"><strong>Senha alterada com sucesso</strong></p>
                </div>
                <h1 className="uppercase">Sua senha foi alterada</h1>
                <p>Utilize sua nova senha para entrar na sua conta</p>
                <button anime="bg-transition" bt="leaked" onClick={(e) => props.onClickModalDisplay(false, e)}>Entrar na minha conta</button>
            </header>
        </section>
    );
}