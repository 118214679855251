import React from 'react'

export default (props) => {
    return (
        <section className="stage" style={{ display: `${(props.display) ? 'block' : 'none'}` }}>
            <div className="bloc-stage">
                <p>Enviamos um e-mail de redefinição de senha para: <strong>{props.email}</strong></p>
                <p>Se você não tiver acesso a este e-mail <a href="/#">faça o cadastro de um novo e-mail</a></p>
            </div>
        </section>
    );
}