import IP from 'ip'
import Bowser from 'bowser'
import JWT from 'jsonwebtoken'

import {encrypt, decrypt} from '../../utils'

import {
    SECRET_KEY
} from '../../constants'

/**
 * Function Authorize Token
 */
export const isAuthorizated = () => {
    const ip = IP.address();
    const token = decrypt(localStorage.getItem('token'));
    const browserParse = Bowser.getParser(window.navigator.userAgent);
    const browser = browserParse.getBrowserName();

    return JWT.verify(token, SECRET_KEY, function (err, decoded) {
        if (err) {
            localStorage.removeItem('token');
            return false;
        }

        if(!(browser === decoded.data.browser) && (ip === decoded.data.ip)){
            localStorage.removeItem('token');
            return false;
        }

        return true;
    });
}

/**
 * Function Create Token
 * @param {obj} id_token 
 */
export function createToken(id_token, email) {

    const browserParse = Bowser.getParser(window.navigator.userAgent);
    const browser = browserParse.getBrowserName();
    const ip = IP.address();

    return JWT.sign({
        data: {
            token: id_token,
            email: email,
            browser: browser,
            ip: ip
        }
    }, SECRET_KEY, { expiresIn: '3h' }, (err, token) => {
        if (err) {
            return false;
        }

        localStorage.setItem('token', encrypt(token));
    });
}

/**
 * Function Validate Token
 * @param {string} type 
 */
export function validateToken(type) {
    const token = decrypt(localStorage.getItem('token'));

    return JWT.verify(token, SECRET_KEY, function (err, decoded) {
        if (err) {
            return false;
        }

        switch (type) {
            case 'token':
                return decoded.data.token;
            case 'email':
                return decoded.data.email;
            case 'phone':
                return decoded.data.phone;
            case 'id_token':
                return decoded.data.id_token;
            case 'refresh_token':
                return decoded.data.refresh_token;
            default:
                return decoded.data.token;
        }
    });
}

/**
 * Function Remove Token
 */
export function removeToken() {
    const token = decrypt(localStorage.getItem('token'));

    return JWT.verify(token, SECRET_KEY, function (err) {
        if (err) {
            return false;
        }
        localStorage.removeItem('token');
        return window.location = '/';
    });
}