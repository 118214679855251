import React from 'react'
import Menu from '../../components/Menu/Internal'
import Footer from '../../components/Footer/Internal' 

export default (props) => {
    return (
        <main page="dashboard">
            <Menu />       
                {props.children}
            <Footer />
        </main>
    );
}