import React from 'react'
import NumberFormat from 'react-number-format'
import { maskCPF } from '../../../../../utils'
import ErrorMessage from '../../../../Errors'

export default (props) => {
    return (
        <section className="stage" style={{ display: `${(props.display) ? 'block' : 'none'}` }}>
            <header>
                <h1 style={{ textTransform: 'uppercase' }}>Falta pouco para aderir o seu seguro!</h1>
                <h2 style={{ textTransform: 'uppercase' }}>Preencha seus dados cadastrais para prosseguir com a compra</h2>
            </header>
            <div className="bloc-stage">
                <div className="breadcrumb">
                    <p className="active-breadc">1. Dados pessoais</p>
                    <p>2. Localização</p>
                    <p>3. Dados de Login</p>
                </div>
                <form className="personal-data" onSubmit={props.handleSubmit}>
                    <fieldset>
                        <legend>Nome completo</legend>
                        <input
                            value={props.fullname}
                            onChange={props.handleChange}
                            type="text"
                            name="fullname"
                            placeholder="Digite seu nome completo"
                            required
                            autoFocus={true}
                        />
                    </fieldset>
                    <fieldset>
                        <legend>Telefone Fixo</legend>
                        <NumberFormat
                            value={props.phone}
                            onChange={props.handleChange}
                            type="text"
                            name="phone"
                            placeholder="Digite seu telefone celular para contato"
                            required
                            format="(##) ####-####" mask="_"
                        />
                    </fieldset>
                    <fieldset>
                        <legend>Data de nascimento</legend>
                        <input
                            value={props.date}
                            onChange={props.handleChange}
                            type="date"
                            name="date"
                            placeholder="Ex. 01/01/1991"
                            required
                        />
                    </fieldset>
                    <fieldset>
                        <legend>CPF</legend>
                        <NumberFormat
                            type="text"
                            name="cpf"
                            value={props.cpf}
                            onChange={props.handleChange}
                            format={maskCPF}
                            placeholder="Digite seu CPF"
                            maxLength={14}
                            required
                        />
                    </fieldset>
                    <ErrorMessage 
                        display={props.displayErrorMessage} 
                        onCloseErrorMessage={(e) => props.onCloseErrorMessage(e)} 
                        message={props.errorMessage} 
                    />
                    <button type="submit" anime="bg-transition" bt="filled">Continuar</button>
                </form>
            </div>
        </section>
    );
}