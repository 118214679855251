import React from 'react'

export default (props) => {
    return (
        <section className="stage" style={{ display: `${(props.display) ? 'block' : 'none'}` }}>
            <p>Parabéns! Você concluiu a contratação do seu Seguro {props.productName} Vivo. 
                Lembre-se que você será cobrado mensalmente em sua conta de telefone fixo e 
                precisará estar em dia com os pagamentos para contar com todos os benefícios do seu seguro.</p>
            <button className="_close" anime="bg-transition" bt="filled" onClick={(e) => props.onClickModalDisplay(false, e)}>Fechar</button>
        </section>
    );
}