import axios from 'axios'
import {
    URL_FIREBASE,
    URL_FIREBASE_AUTH,
    API_KEY_FIREBASE
} from '../../constants'

const HEADERS1 = {
    'Content-Type': 'application/json'
}

const HEADERS2 = {
    'Content-Type': 'application/x-www-form-urlencoded'
}

/**
 * Function Verify Custom Token
 * @param {json} body 
 */
export async function verifyCustomToken(body) {
    try {
        const response = await axios.post(`${URL_FIREBASE}/verifyCustomToken?key=${API_KEY_FIREBASE}`, body, { headers: HEADERS1 });
        return { status: true, id_token: response.data.idToken, refresh_token: response.data.refreshToken };
    } catch (error) {
        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
        }
        
        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
        }

        return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
    }
}

/**
 * Function Refresh Token
 * @param {json} body 
 */
export async function refreshToken(body) {
    try {
        const response = await axios.post(`${URL_FIREBASE_AUTH}/token?key=${API_KEY_FIREBASE}`, body, { headers: HEADERS2 });
        return { status: true, id_token: response.data.id_token };
    } catch (error) {
        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
        }
        
        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
        }

        return { status: false, message: `Ops, ocorreu um erro. Envie um email para: atendimentoaffinity@bbmapfre.com.br` };
    }
}