import React, { Component } from 'react'
import ModalTemplate from '../Template'
import FormSelectMethodPurchase from '../../Forms/Modal/NewSubscription/SelectMethodPurchase'

export default class ModalNewSubscription extends Component {

    /**
     * Constructor
     */
    constructor() {
        super();

        this.state = {
            errorMessage: null,
            displayHeader: true,
            displayErrorMessage: false,
            displayModal: false,
            displayFormSelectMethodPurchase: true
        }

        this.baseState = this.state;
    }

    /**
     * On Click Modal Display
     */
    onClickModalDisplay = (status, e) => {
        e.preventDefault();
        if (status) {
            this.setState({ displayModal: true });
            return;
        }

        this.setState(this.baseState);
        return;
    }

    /**
     * Render Component
     */
    render() {
        const {
            displayModal,
            displayFormSelectMethodPurchase
        } = this.state;

        return (
            <>
                <a href="/#" className="bt-plan" onClick={(e) => this.onClickModalDisplay(true, e)}><p>EU QUERO</p></a>

                <ModalTemplate
                    display={displayModal}
                    headerTitle={'Ótima escolha!'}
                    onClickModalDisplay={this.onClickModalDisplay}
                >
                    <FormSelectMethodPurchase
                        productId={this.props.productId}
                        display={displayFormSelectMethodPurchase}
                    />
                </ModalTemplate>
            </>
        )
    }
}