/** FIREBASE ENVIRONMENT */
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
export const FIREBASE_DATABASE_URL = process.env.REACT_APP_FIREBASE_DATABASE_URL
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
export const FIREBASE_MESSAGING_SEND_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SEND_ID
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID
export const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID

/** APPLICATION ENVIRONMENT */
export const NODE_ENV = process.env.NODE_ENV
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY
export const BRAND_ID = process.env.REACT_APP_BRAND_ID
export const BRAND_NAME = process.env.REACT_APP_BRAND_NAME
export const REQUESTER_NAME = process.env.REACT_APP_REQUESTER_NAME
export const URL_BACKEND = process.env.REACT_APP_URL_BACKEND
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET
export const URL_FIREBASE = process.env.REACT_APP_URL_FIREBASE
export const URL_FIREBASE_AUTH = process.env.REACT_APP_URL_FIREBASE_AUTH
export const API_KEY_FIREBASE = process.env.REACT_APP_API_KEY_FIREBASE
export const URL_CHAT = process.env.REACT_APP_URL_CHAT
export const KEY_GOOGLE_RECAPTCHA = process.env.REACT_APP_API_KEY_GOOGLE_RECAPTCHA
export const CRYPT_KEY = process.env.REACT_APP_CRYPT_KEY
export const CRYPT_IV = process.env.REACT_APP_CRYPT_IV

/** PRODUCT PROTECTED ACCOUNT */
export const PRODUCT_PROTECTED_ACCOUNT = [
    {   
        plan: 1,
        product_id: parseInt(process.env.REACT_APP_PRODUCT_ID_PROTECTED_ACOUNT_PLAN_1),
        price: "R$ 5,90",
        coverage_1: "R$ 840,00",
        coverage_2: "R$ 840,00",       
        coverage_3: "R$ 280,00",
        coverage_4: "R$ 280,00",
        coverage_5: "Não"
    },
    {
        plan: 2,
        product_id: parseInt(process.env.REACT_APP_PRODUCT_ID_PROTECTED_ACOUNT_PLAN_2),
        price: "R$ 7,99",
        coverage_1: "R$ 1.500,00",
        coverage_2: "R$ 1.500,00",       
        coverage_3: "R$ 280,00",
        coverage_4: "R$ 280,00",
        coverage_5: "Não"
    },
    {
        plan: 3,
        product_id: parseInt(process.env.REACT_APP_PRODUCT_ID_PROTECTED_ACOUNT_PLAN_3),
        price: "R$ 9,99",
        coverage_1: "R$ 840,00",
        coverage_2: "R$ 840,00",       
        coverage_3: "R$ 280,00",
        coverage_4: "R$ 280,00",
        coverage_5: "Não"
    },
    {
        plan: 4,
        product_id: parseInt(process.env.REACT_APP_PRODUCT_ID_PROTECTED_ACOUNT_PLAN_4),
        price: "R$ 13,99",
        coverage_1: "R$ 2.000,00",
        coverage_2: "R$ 2.000,00",       
        coverage_3: "R$ 280,00",
        coverage_4: "R$ 280,00",
        coverage_5: "Sim"
    },
    {
        plan: 5,
        product_id: parseInt(process.env.REACT_APP_PRODUCT_ID_PROTECTED_ACOUNT_PLAN_5),
        price: "R$ 17,99",
        coverage_1: "R$ 2.500,00",
        coverage_2: "R$ 2.500,00",       
        coverage_3: "R$ 280,00",
        coverage_4: "R$ 280,00",
        coverage_5: "Sim"
    }                 
]

/** PRODUCT HOME INSURANCE */
export const PRODUCT_HOME_INSURANCE = [
    {
        plan: 1,
        product_id: parseInt(process.env.REACT_APP_PRODUCT_ID_HOME_INSURANCE_PLAN_1),
        price: "R$ 6,90",
        coverage_1: "R$ 25.000,00",
        coverage_2: "R$ 500,00",       
        coverage_3: "R$ 1.000,00",
        coverage_4: "",
        coverage_5: ""
    },
    {
        plan: 2,
        product_id: parseInt(process.env.REACT_APP_PRODUCT_ID_HOME_INSURANCE_PLAN_2),
        price: "R$ 6,99",
        coverage_1: "R$ 25.000,00",
        coverage_2: "",       
        coverage_3: "R$ 1.000,00",
        coverage_4: "",
        coverage_5: "Personalizado"
    },
    {
        plan: 3,
        product_id: parseInt(process.env.REACT_APP_PRODUCT_ID_HOME_INSURANCE_PLAN_3),
        price: "R$ 9,90",
        coverage_1: "R$ 30.000,00",
        coverage_2: "R$ 1.000,00", 
        coverage_3: "R$ 1.000,00",
        coverage_4: "",
        coverage_5: ""
    },
    {
        plan: 4,
        product_id: parseInt(process.env.REACT_APP_PRODUCT_ID_HOME_INSURANCE_PLAN_4),
        price: "R$ 9,99",
        coverage_1: "R$ 30.000,00",
        coverage_2: "", 
        coverage_3: "R$ 1.000,00",
        coverage_4: "",
        coverage_5: "Personalizado"
    },
    {
        plan: 5,
        product_id: parseInt(process.env.REACT_APP_PRODUCT_ID_HOME_INSURANCE_PLAN_5),
        price: "R$ 14,90",
        coverage_1: "R$ 30.000,00",
        coverage_2: "R$ 1.000,00", 
        coverage_3: "R$ 1.000,00",
        coverage_4: "",
        coverage_5: "Personalizado"
    },
    {
        plan: 6,
        product_id: parseInt(process.env.REACT_APP_PRODUCT_ID_HOME_INSURANCE_PLAN_6),
        price: "R$ 18,90",
        coverage_1: "R$ 35.000,00",
        coverage_2: "R$ 1.250,00", 
        coverage_3: "R$ 5.000,00",
        coverage_4: "R$ 1.250,00",
        coverage_5: "Personalizado"
    },
    {
        plan: 7,
        product_id: parseInt(process.env.REACT_APP_PRODUCT_ID_HOME_INSURANCE_PLAN_7),
        price: "R$ 21,90",
        coverage_1: "R$ 40.000,00",
        coverage_2: "R$ 1.500,00", 
        coverage_3: "R$ 7.500,00",
        coverage_4: "R$ 1.500,00",
        coverage_5: "Personalizado"
    }                  
]