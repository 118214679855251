import React, { Component } from 'react'

import {
    validatorEmail,
    createToken,
    encrypt
} from '../../utils'

import {
    authorization,
    verifyCustomToken
} from '../../services'

import Loading from '../../components/Loading'
import FormSendEmailPassword from '../../components/Forms/Login/SendEmailPassword'
import ModalNewPassword from '../../components/Modal/NewPassword'
import ModalCreateUserPassword from '../../components/Modal/CreateUserPassword'
import ModalNewSubscriptionProducts from '../../components/Modal/NewSubscriptionProducts'

export default class Login extends Component {

    /**
     * Constructor
     */
    constructor() {
        super();

        this.state = {
            loading: false,
            errorMessage: null,
            displayErrorMessage: false,
            email: '',
            password: ''
        }

        this.baseState = this.state;
        this.handleChangeFormSendEmailPassword = this.handleChangeFormSendEmailPassword.bind(this);
        this.handleSubmitFormSendEmailPassword = this.handleSubmitFormSendEmailPassword.bind(this);
    }

    /**
    * On Close Error Message
    */
    onCloseErrorMessage = (e) => {
        this.setState({
            displayErrorMessage: false,
            errorMessage: null
        });
    }

    /**
    * Handle Change Form Send Email and Password
    * @param {event} e 
    */
    handleChangeFormSendEmailPassword(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    /**
    * Handle Submit Form Send Email and Password
    * @param {event} e 
    */
    async handleSubmitFormSendEmailPassword(e) {
        e.preventDefault();

        const {
            email,
            password
        } = this.state;

        const validator = validatorEmail(email);

        if (!validator.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: validator.message
            });
            return;
        }

        this.setState({
            loading: true,
            displayErrorMessage: false,
            errorMessage: null
        });

        let bodyEncrypted = {"ciphertext": encrypt({email: email, password: password})};
        const response1 = await authorization(bodyEncrypted);

        if (!response1.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: response1.message
            });
            return;
        }

        const response2 = await verifyCustomToken({
            token: response1.custom_token,
            returnSecureToken: true
        });

        if (!response2.status || !response2.id_token) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: response2.message
            });
            return;
        }

        createToken(
            response2.id_token,
            email
        );

        setTimeout(async () => {
            window.location = `/dashboard`;
        }, 3000);
    }

    /**
     * Render Component
     */
    render() {
        const {
            loading,
            email,
            password,
            errorMessage,
            displayErrorMessage
        } = this.state;

        return (
            <main page="login" style={{ backgroundImage: 'url("assets/images/bg/background-top.jpg")' }}>
                <Loading display={loading} />
                <div className="grid" anime="w-transition" box="shadow" style={{ display: 'initial', maxWidth: 400 }}>
                    <div className="form-login" style={{ borderRight: 'none' }}>
                        <p style={{ textTransform: 'uppercase' }}><strong>Entrar na sua conta</strong></p>
                        <FormSendEmailPassword
                            email={email}
                            password={password}
                            errorMessage={errorMessage}
                            displayErrorMessage={displayErrorMessage}
                            handleChange={this.handleChangeFormSendEmailPassword}
                            handleSubmit={this.handleSubmitFormSendEmailPassword}
                            onCloseErrorMessage={this.onCloseErrorMessage}
                        />
                        <ModalNewPassword />
                    </div>
                    <section>
                        <ModalCreateUserPassword />
                        <ModalNewSubscriptionProducts />
                    </section>
                    {/* <section className="infos-login">
                        <p><strong>Sou cliente, mas ainda não criei minha senha de acesso</strong></p>
                        <p>Caso já possua um seguro <strong>MAPFRE VIVO</strong>, mas ainda não criou sua senha de acesso, clique abaixo</p>
                        <ModalCreateUserPassword />
                    </section> */}
                </div>
            </main>
        );
    }
}