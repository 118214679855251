import React, { Component } from 'react'
import Layout from '../../layout/Internal'
import Loading from '../../components/Loading'
import CardProductsActive from '../../components/Cards/Products/Active'
import CardProductsInactive from '../../components/Cards/Products/Inactive'
// import ModalNewSubscriptionProducts from '../../components/Modal/NewSubscriptionProducts'

import {
    sendGetSalesByUser
} from '../../services'

export default class Dashboard extends Component {

    /**
     * Constructor
     */
    constructor() {
        super();

        this.state = {
            loading: false,
            errorMessage: null,
            displayErrorMessage: false,
            displayActiveProducts: true,
            displayInactiveProducts: false,
            productsAtivate: [],
            productsInactive: []
        }
    }

    /**
     * Component Did Mount
     */
    componentDidMount() {
        this.sendGetSalesByUser();
    }

    /**
    * On Close Error Message
    */
    onCloseErrorMessage = (e) => {
        this.setState({
            displayErrorMessage: false,
            errorMessage: null
        });
    }

    /**
     * Function On Click Select Type Product
     * @param {object} e 
     */
    onClickSelectTypeProduct = (status) => {
        if(status){
            this.setState({
                displayActiveProducts: true,
                displayInactiveProducts: false,
            });
            return;
        }
        
        this.setState({
            displayActiveProducts: false,
            displayInactiveProducts: true,
        });
    }

    /**
    * Function Send Get Sales By User
    */
    async sendGetSalesByUser() {

        this.setState({
            loading: true,
            displayErrorMessage: false,
            errorMessage: null
        });

        const response = await sendGetSalesByUser();

        if (!response.status) {
            this.setState({
                loading: false,
                displayErrorMessage: true,
                errorMessage: response.message
            });
            return;
        }

        this.setState({
            loading: false,
            productsAtivate: response.productsActive,
            productsInactive: response.productsInactive
        })
    }

    /**
     * Render Component
     */
    render() {

        const {
            productsAtivate,
            productsInactive,
            displayActiveProducts,
            displayInactiveProducts,
            loading
        } = this.state;

        if (loading) {
            return <Loading display={loading} />;
        } else {
            return (
                <Layout>
                    <section className="content res-padding">
                        <div className="center">
                            <div className="title-content">
                                <p style={{ textTransform: 'uppercase' }}>
                                    <strong>Meus produtos</strong>
                                </p>
                            </div>
                            <div className="tabs">
                                <p className={`${(displayActiveProducts) ? 'tab1 active-tab' : 'tab1'}`} style={{ textTransform: 'uppercase' }} onClick={() => this.onClickSelectTypeProduct(true)}>
                                    <strong>Produtos ativos</strong>
                                </p>
                                <p className={`${(displayInactiveProducts) ? 'tab2 active-tab' : 'tab2'}`} style={{ textTransform: 'uppercase' }} onClick={() => this.onClickSelectTypeProduct(false)}>
                                    <strong>Produtos cancelados</strong>
                                </p>
                            </div>
                            <div className="itens-tab">
                                <div className="iprod-active" style={{ display: `${(displayActiveProducts) ? 'block' : 'none'}` }}>
                                    <CardProductsActive
                                        rows={productsAtivate}
                                    />
                                </div>
                                <div className="iprod-inative" style={{ display: `${(displayInactiveProducts) ? 'block' : 'none'}` }}>
                                    <CardProductsInactive
                                        rows={productsInactive}
                                    />

                                    <div className="cancel-prod-info">
                                        <p><strong>Deseja cancelar o seu Seguro MAPFRE Vivo?</strong></p>
                                        <br />
                                        <p>Envie um e-mail com as seguintes informações:</p>
                                        <p>Nome Completo, CPF e o Número da Linha Fixa da Vivo para: atendimentoaffinity@bbmapfre.com.br</p>
                                        
                                    </div>
                                </div>
                            </div>
                            {/* <ModalNewSubscriptionProducts /> */}
                        </div>
                    </section>
                </Layout>
            );
        }
    }
}