import React from 'react'
import NumberFormat from 'react-number-format'
import ErrorMessage from '../../../../Errors'

export default (props) => {
    return (
        <section className="stage" style={{ display: `${(props.display) ? 'block' : 'none'}` }}>
            <header>
                <h1 style={{ textTransform: 'uppercase' }}>Falta pouco para aderir o seu seguro!</h1>
                <h2 style={{ textTransform: 'uppercase' }}>Precisamos validar seu número VIVO Fixo</h2>
            </header>
            <div className="bloc-stage">
                <div className="msg-comp">
                    <p>Digite abaixo o <strong>Código de Acesso</strong> que iremos passar através de uma ligação automática para o número:
                    <br /><br />
                    <strong>+{props.phone}</strong>&nbsp;&nbsp;<a href="/#" onClick={(e) => props.onClickBackSendPincode(e)}>Alterar número</a></p>
                </div>
                <form className="new-pass" onSubmit={props.handleSubmit}>
                    <fieldset>
                        <legend>Digite seu código de acesso</legend>
                        <NumberFormat
                            value={props.pincode}
                            onChange={props.handleChange}
                            name="pincode"
                            placeholder="Código de acesso"
                            required
                            format="######"
                            autoFocus={true}
                        />
                    </fieldset>
                    <ErrorMessage
                        display={props.displayErrorMessage}
                        onCloseErrorMessage={(e) => props.onCloseErrorMessage(e)}
                        message={props.errorMessage}
                    />
                    <p style={{ fontSize: 12, textAlign: 'center', textDecoration: 'underline', cursor: 'pointer', color: 'rgba(105,105,105,1)', display: `${(props.btnResendPincode) ? 'block' : 'none'}` }} onClick={props.handleResendPincode}>Reenviar código</p>
                    <p style={{ fontSize: 12, textAlign: 'center', textDecoration: 'underline', color: 'rgba(105,105,105,1)', display: `${(props.messageResendPincode) ? 'block' : 'none'}` }}>Reenviando em {props.countResendPincode} segundos...</p>
                    <button type="submit" anime="bg-transition" bt="filled">Continuar</button>
                    <small>Você receberá a ligação em até: <strong>60 segundos</strong></small>
                </form>
            </div>
        </section>
    );
}