import React from 'react'
import ErrorMessage from '../../../../Errors'

export default (props) => {
    return (
        <section className="stage" style={{ display: `${(props.display) ? 'block' : 'none'}` }}>
            <header>
                <div className="msg-congrats">
                    <p style={{ textTransform: 'uppercase' }}><strong>Cadastro concluído com sucesso</strong></p>
                </div>
                <h1 style={{ textTransform: 'uppercase' }}>Estamos quase lá</h1>
                <p>Enviamos um e-mail de confirmação para: <strong>{props.email}</strong></p>
                <p>Acesse seu e-mail e clique no link de confirmação para confirmar a sua conta!</p>
                <p>Caso não tenha recibo o e-mail, clique abaixo para reenviar</p>
                <a href="/#" anime="bg-transition" bt="leaked" onClick={(e) => props.handleSubmitFormSendRegistrationLogin(e)}>Reenviar e-mail de confirmação</a>
                <ErrorMessage 
                    display={props.displayErrorMessage} 
                    onCloseErrorMessage={(e) => props.onCloseErrorMessage(e)} 
                    message={props.errorMessage} 
                />
            </header>
        </section>
    );
}