import React from 'react'

export default (props) => {
    return (
        <div>
            <div className="_mask" style={{ display: `${(props.display) ? 'block' : 'none'}` }} />
            <div className="_modal" style={{ display: `${(props.display) ? 'block' : 'none'}` }}>
                <div className="header">
                    <p className="title">{props.headerTitle}</p>
                    <span className="_close" onClick={(e) => props.onClickModalDisplay(false, e)}>
                        <i className="fas fa-times" />
                    </span>
                </div>
                <div className="content">
                    {props.children}
                </div>
            </div>
        </div>
    );
}